/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";

// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from './views/Dashboard/Dashboard';
import Reports from './lprfolders/reports/Reports'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import StorageIcon from '@material-ui/icons/Storage';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PeopleIcon from '@material-ui/icons/People';
import SupervisedUserCircleRoundedIcon from '@material-ui/icons/SupervisedUserCircleRounded';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
const dashboardRoutesAdmin = [
  {
    path: "Dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/"
  },
  {
    path: "vehicle",
    name: "Vehicle Database",
    icon: StorageIcon,
    component: DashboardPage,
    layout: "/"
  },
  {
    path: "search",
    name: "Track Vehicles",
    icon: FindReplaceIcon,
    component: DashboardPage,
    layout: "/"
  },

  // {
  //   path: "company",
  //   name: "Manage Companies",
  //   icon: BusinessCenterIcon,
  //   component: Dashboard,
  //   layout: "/"
  // },
  // {
  //   path: "user",
  //   name: "Manage Users",
  //   icon: PeopleIcon,
  //   component: DashboardPage,
  //   layout: "/"
  // },
  // {
  //   path: "edgebox",
  //   name: "Edge Box Management",
  //   icon: AllInboxIcon,
  //   component: DashboardPage,
  //   layout: "/"
  // },
  {
    path: "visitor",
    name: "Manage Visitors",
    icon: SupervisedUserCircleRoundedIcon,
    component: DashboardPage,
    layout: "/"
  },
  {
    path: "tenant",
    name: "Manage Tenants",
    icon: AccountCircleIcon,
    component: DashboardPage,
    layout: "/"
  },
  {
    path: "reports",
    name: "Reports",
    icon: AssessmentIcon,
    component: Reports,
    layout: "/"
  },
  {
    path: "help",
    name: "Help & Support",
    icon: ContactSupportIcon,
    component: DashboardPage,
    layout: "/"
  },
];

export default  dashboardRoutesAdmin
