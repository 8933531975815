import React, { Component } from "react";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { withRouter } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import { HOST } from '../../lprfolders/config';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
import Settings from '../../lprfolders/GlobalConstant/Settings';
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InfoIcon from '@material-ui/icons/Info';
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};
class View extends Component {
    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
        this.state = {
            companyname: '',
            mobile: '',
            email: '',
            website: '',
            companyurl: '',
            ctype: '',
            results: [],
            flagError3: false,
            loader: false,
            addButton: true,
            facebookurl: "",
            
            instagramurl: "",
            imageURL: "",
            twitterurl: "",
            zomatourl: "",
            linkedinurl: "",
            google: "",
            sms: "",
            ratingurl: "",
            selectedFile: '',
            companytype: "",
            companytypesarray: [],
            roletypesarray: [],
            roletype: "",
            requirederror: false,
            flagerror: false,
            flagerror1: false,
            bordererror: false,
            netWorkError: false,
            flagError: false,
            erroertext: "",
            success: false,
            pwd: "",
            firstname: "",
            lastname: "",
            mobile:"",
            email:"",
            tenant:""
        }
    }
    componentDidMount() {
        this.gettenantDetails()
    }
    gettenantDetails = () => {
        this.setState({ loader: true })
        let api2 = HOST + "/tenant/get/" + this.props.location.state.propdata.tenantid
        axios.get(api2, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((response )=> {
                this.setState({
                    tenant: response.data.tenant,
                    firstname: response.data.firstname,
                    lastname: response.data.lastname,
                    mobile:response.data.mobile,
                    email: response.data.email,
                    pwd:response.data.pwd,
                    
                   
                })
                this.setState({ loader: false })

            }).catch(err => {
                if (err.message === "Network Error") {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
                if (err.response) {
                    if (err.response.status === 502) {
                        this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                    }
                }
            })

    }
    render() {
        const { classes } = this.props
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Tenant record")}</h4>
                                <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Details pertaining to the Tenant")}</p>
                            </CardHeader>
                            <CardBody >
                            {this.state.netWorkError ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={this.state.erroertext}
                                    // close
                                    color="danger"
                                /> : ""}
                                {this.state.loader ? <LoaderComponent /> : ""}
                                <GridContainer>
                                    <GridItem style={{marginTop:-20}} xs={12} sm={12} md={6}>
                                        <h6 className={classes.cardCategory}  style={{borderBottom:"0.8px ridge",fontWeight:"500"}}>Company </h6>
                                        <h4 style={{marginTop:-22,fontSize:18}} >{this.state.tenant}</h4>
                                    </GridItem>
                                    <GridItem style={{marginTop:-20}} xs={12} sm={12} md={6}>
                                        <h6 className={classes.cardCategory}  style={{borderBottom:"0.8px ridge",fontWeight:"500"}}>First Name </h6>
                                        <h4 style={{marginTop:-22,fontSize:18}} >{this.state.firstname}</h4>
                                    </GridItem>
                                    <GridItem style={{marginTop:-20}} xs={12} sm={12} md={6}>
                                    <h6 className={classes.cardCategory} style={{borderBottom:"0.8px ridge",fontWeight:"500"}}>Last Name </h6>
                                    <h4 style={{marginTop:-22,fontSize:18}} >{this.state.lastname}</h4>
                                    </GridItem>
                                
                                    <GridItem style={{marginTop:-20}} xs={12} sm={12} md={6}>
                                    <h6 className={classes.cardCategory} style={{borderBottom:"0.8px ridge",fontWeight:"500"}}>Mobile Number </h6>
                                    <h4 style={{marginTop:-22,fontSize:18}} >{this.state.mobile}</h4>
                                    </GridItem>
                                
                                    <GridItem style={{marginTop:-20}} xs={12} sm={12} md={6}>
                                    <h6 className={classes.cardCategory} style={{borderBottom:"0.8px ridge",fontWeight:"500"}}>Email </h6>
                                    <h4 style={{marginTop:-22,fontSize:18}} >{this.state.email}</h4>
                                    </GridItem>
                                    <GridItem style={{marginTop:-20}} xs={12} sm={12} md={6}>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={5} style={{marginTop:-20}}>
                                        <Button color="primary" onClick={() => {
                                            this.props.history.push({
                                                pathname: '/tenant/edit', state: {
                                                    propdata: this.props.location.state.propdata
                                                }
                                            })
                                        }}> <EditIcon />Edit<span style={{ display: "inline-block", width: "21px" }}></span></Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={1} style={{marginTop:-20}}>
                                        <Button color="white" onClick={() => {
                                            this.props.history.push('/tenant')
                                        }}><CancelPresentationIcon />Cancel</Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                    </GridItem>
                                </GridContainer>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withRouter((withStyles(styles)(View)));
