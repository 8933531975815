import { withStyles } from "@material-ui/core/styles";
import React, { Component } from 'react';
import Navbar from "../../components/Navbars/Navbar.js";
import routes from '../../routes'
import styles from "../../assets/jss/material-dashboard-react/layouts/adminStyle.js";
import NavBarStyles from "../../assets/jss/material-dashboard-react/components/headerStyle.js";
import SidebarStyles from "../../assets/jss/material-dashboard-react/components/sidebarStyle.js";
import bgImage from "../../assets/img/sidebar-2.jpg";
import logo from "../../assets/img/reactlogo.png";
import Sidebar from "../../components/Sidebar/Sidebar.js";
import Footer from '../footer';
import DashboardPage from '../../views/helpSupport/HelpSupportTable'
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false
    }
    this.mainPanel = React.createRef();
  }
  handleImageClick = image => {
    this.setState({ image });
  };
  handleColorClick = color => {
    this.setState({ color });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute = () => {
    return window.location.pathname !== "/";
  };

  render() {

    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
           <Sidebar
          routes={routes}
          logoText={"Creative Tim"}
          logo={logo}
          image={bgImage}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={"green"}
          {...this.props}
        />
         <div className={classes.mainPanel}>
          <Navbar
            routes={routes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...this.props}
          />
          <div className={classes.content}>
            <div className={classes.container}>
                 <DashboardPage />
              </div>
          </div>
          {this.getRoute() ? <Footer /> : null}
        </div>
      </div>
    );
  }
}

export default withStyles(styles,NavBarStyles,SidebarStyles,)(Dashboard);