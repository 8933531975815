
import React, { Component } from 'react'
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import Settings from '../../lprfolders/GlobalConstant/Settings';
import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Alert from '../../lprfolders/GlobalConstant/Alert'
import axios from 'axios';
import { HOST } from '../../lprfolders/config';
import Snackbar from "components/Snackbar/Snackbar.js";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import InfoIcon from '@material-ui/icons/Info';
import { withRouter } from "react-router-dom";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};


class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
        this.state = {
            currentpwd: "",
            newpwd: "",
            confirmpwd: "",
            flagError: false,
            netWorkError: false,
            erroertext: "",
            inputname: "",
            tc: false,
            bc: false,
            flagnotmatcherror: false,
            online: window.navigator.onLine

        }
    }
    componentDidMount() {
        // this.getvisitordetails()
        window.addEventListener('offline', this.handleNetworkChange);
        window.addEventListener('online', this.handleNetworkChange);
    }

    componentWillUnmount() {
        window.removeEventListener('offline', this.handleNetworkChange);
        window.removeEventListener('online', this.handleNetworkChange);
    }
    handleNetworkChange = () => {
        this.setState({ online: window.navigator.onLine });
    }
    showNotification = place => {
        switch (place) {
            case "tc":
                if (!this.state.tc) {
                    this.setState({ tc: true, erroertext: Alert.Successadd });
                    setTimeout(() => { this.setState({ tc: false }) }, 4000)
                }
                break;
            case "bc":
                if (!this.state.bc) {
                    this.setState({ bc: true });
                    setTimeout(() => { this.setState({ bc: false }) }, 4000)
                }
                break;
            default:
                break;
        }
    };
    Changepwd = () => {
        let api = HOST + '/user/updatepassword'
        let body = {
            currentpwd: this.state.currentpwd,
            newpwd: this.state.confirmpwd
        }
        if (this.state.newpwd === this.state.confirmpwd) {
            if (Settings.Password(this.state.newpwd, "NewPassword").status) {
                this.setState({ loader: true })
                if (Settings.Password(this.state.confirmpwd, "ConfirmPassword").status) {
                    if (this.state.online) {
                        axios.put(api, body, {
                            headers: {
                                "authToken": this.token,
                                'Accept': 'application/json',
                                //   'Content-Type': 'application/json'
                            }
                        }).then(response => {
                            if (response.data.statusid === 201) {
                                this.setState({ loader: false })
                                this.showNotification("tc")
                            }
                            if (response.data.statusid === 202) {
                                this.setState({ flagError: true, loader: false, erroertext: response.data.msg, inputname: "CurrentPassword" })
                                setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                            }
                        }).catch(err => {
                            if (err.message === "Network Error") {
                                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                            }
                            if (err.response) {
                                if (err.response.status === 502) {
                                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                                }
                            }
                        })
                    }
                    else {
                        this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                    }
                } else {
                    this.setState({ flagError: true, erroertext: Settings.Password(this.state.confirmpwd, "ConfirmPassword").errmessgae, inputname: Settings.Password(this.state.confirmpwd, "ConfirmPassword").inputname })
                    setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                }
            } else {
                this.setState({ flagError: true, erroertext: Settings.Password(this.state.newpwd, "NewPassword").errmessgae, inputname: Settings.Password(this.state.newpwd, "NewPassword").inputname })
                setTimeout(() => { this.setState({ flagError: false }) }, 3000);
            }
        } else {
            this.setState({ flagnotmatcherror: true, erroertext: "New password and confirm passowrd didn't match", loader: false })
            setTimeout(() => { this.setState({ flagnotmatcherror: false }) }, 3000);
        }
    }
    render() {
        const { classes } = this.props
        return (
            <div>
                 {this.state.tc ? <Snackbar
                        place="tc"
                        color="success"
                        icon={ThumbUpIcon}
                        message={Alert.PasswordChange}
                        open={this.state.tc}
                        closeNotification={() => this.setState({ tc: false })}
                        close
                    /> : ""}
                <GridContainer>
                   
                    <GridItem xs={12} sm={12} md={2}>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Change Password</h4>
                                <p className={classes.cardCategoryWhite}>Please fill in all required feilds</p>
                            </CardHeader>
                            <CardBody>
                                {this.state.netWorkError ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={this.state.erroertext}
                                    // close
                                    color="danger"
                                /> : ""}
                                {this.state.loader ? <LoaderComponent /> : ""}
                                {this.state.flagnotmatcherror ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={this.state.erroertext}
                                    // close
                                    color="danger"
                                /> : ""}
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* Current Password"
                                            id="Current Password"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => this.setState({ currentpwd: event.target.value }),
                                                type: "password",
                                                value: this.state.currentpwd,
                                                error: (this.state.flagError && this.state.inputname === "CurrentPassword") || (this.state.requirederror && this.state.currentpwd === "") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.currentpwd ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && (this.state.inputname === "CurrentPassword") ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>

                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* New Password(min. 3 & max. 20)"
                                            id="New password"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    this.setState({ newpwd: event.target.value })
                                                    if (Settings.Password(this.state.newpwd, "NewPassword").status || event.target.value ==="") {
                                                        this.setState({ flagError: false, erroertext: "" })
                                                    } else {
                                                        this.setState({ flagError: true, erroertext: Settings.Password(this.state.newpwd, "NewPassword").errmessgae, inputname: Settings.Password(this.state.newpwd, "NewPassword").inputname })
                                                        setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                                                    }
                                                },
                                                type: "password",
                                                value: this.state.newpwd,
                                                error: ((this.state.flagnotmatcherror || this.state.flagError) && (this.state.inputname === "NewPassword")) || (this.state.requirederror && this.state.newpwd === "") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.newpwd ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "NewPassword" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* Confirm New Password(min. 3 & max. 20)"
                                            id="Confirm new Password"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    this.setState({ confirmpwd: event.target.value })
                                                    if (Settings.Password(this.state.confirmpwd, "ConfirmPassword").status || event.target.value ==="") {
                                                        this.setState({ flagError: false, erroertext:"" })
                                                    } else {
                                                        this.setState({ flagError: true, erroertext: Settings.Password(this.state.confirmpwd, "ConfirmPassword").errmessgae, inputname: Settings.Password(this.state.confirmpwd, "ConfirmPassword").inputname })
                                                        setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                                                    }
                                                },
                                                type: "password",
                                                value: this.state.confirmpwd,
                                                error: ((this.state.flagnotmatcherror || this.state.flagError) && (this.state.inputname === "ConfirmPassword")) || ((this.state.requirederror) && (this.state.confirmpwd === "")) ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.confirmpwd ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "ConfirmPassword" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                {this.state.currentpwd !== "" && this.state.newpwd !== "" && this.state.confirmpwd !== "" ?
                                    <Button color="primary" onClick={this.Changepwd}><DoneOutlineIcon />save<span style={{ display: "inline-block", width: "21px" }}></span></Button> :
                                    <Button onClick={() => {
                                        if (Settings.requireerrors([this.state.currentpwd, this.state.newpwd, this.state.confirmpwd]).status === true) {
                                            this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.currentpwd, this.state.newpwd, this.state.confirmpwd]).errmessgae })
                                            setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                                        }
                                    }}><DoneOutlineIcon />save<span style={{ display: "inline-block", width: "21px" }}></span></Button>
                                }
                            </CardFooter>
                        </Card>
                    </GridItem>

                </GridContainer>

            </div>
        );
    }
}

export default withRouter((withStyles(styles)(ChangePassword)));

