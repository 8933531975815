
import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import success from './img/sms.gif';
import Button from "components/CustomButtons/Button.js";
import LockIcon from '@material-ui/icons/Lock';
import Settings from './GlobalConstant/Settings'
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    dialog: {
        // backgroundImage: "url(http://lpr.gaido.in/static/media/bulkcar.8585b4d8.jpg)",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: "100%",
        overflowX: "hidden",
        overflowy: "auto",
    },
    root: {
        width: "100%",
        minHeight: 330,
        position: "relative",
        top: "50%",
        left: "50%",
        // webkitTransform:" translateY(-50%) translateX(-50%)",
        transform: "translateY(-50%) translateX(-50%)",
    }
};
const useStyles = makeStyles(styles);
export default function SuccessSms(props) {
    const classes = useStyles();
    return (
        <div className={classes.dialog}>
            <div className={classes.root}>
                <GridContainer  >
                    <GridItem xs={12} sm={12} md={4}>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} className={classes.root}>
                        <Card >
                            <CardHeader color="primary" >
                                <h4 className={classes.cardTitleWhite} >Reset password </h4>
                                <p className={classes.cardCategoryWhite}>SMS for changing new password.</p>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={1}>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={8}>
                                        <img src={success} alt="Gaido Technologies" style={{ width: "100%" }} />
                                        <p>We are sent a reset link to your registered mobile number.</p>
                                        <p>Please go to messages(SMS) and click on reset password link to update your new password.</p>
                                        <p>For any queries reach out to <span color="primary" style={{ marginRight: 5, }} > <a href="mailto:info@gaido.in?subject=Gaido Request Call Back&body" style={{ textDecoration: "none", cursor: "pointer", color: "#1877f2", fontWeight: "bold" }} target="_blank" rel="noopener noreferrer">{Settings.titleCase("info@gaido.in")}</a></span></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={3}>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={1}>
                                        <Button color="info" onClick={() => {
                                            props.history.push('/')
                                        }}> <LockIcon />Sign In</Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                    </GridItem>
                                </GridContainer>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
