
import React, { Component } from 'react';
// @material-ui/core components
import axios from 'axios'
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Checkbox from "@material-ui/core/Checkbox";
import { green } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AUTH, HOST } from './config';
// import TextField from '@material-ui/core/TextField';
import LoaderComponent from './GlobalConstant/LoaderComponent'
import Settings from './GlobalConstant/Settings';
// import {_goto} from './GlobalConstant/OfflineNotice'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InputAdornment from '@material-ui/core/InputAdornment';
import InfoIcon from '@material-ui/icons/Info';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CookieSetService from './GlobalConstant/CookieSetService';
import publicIp from 'react-public-ip';

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    dialog: {
        // backgroundImage: "url(http://lpr.gaido.in/static/media/bulkcar.8585b4d8.jpg)",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: "100%",
        overflowX: "hidden",
        overflowy: "auto",
    },
    root: {
        width: "100%",
        minHeight: 330,
        position: "relative",
        top: "50%",
        left: "50%",
        // webkitTransform:" translateY(-50%) translateX(-50%)",
        transform: "translateY(-50%) translateX(-50%)",
    }
};
class login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Username: "",
            Password: "",
            flagError: false,
            netWorkError: false,
            flagError2: false,
            flagError3: false,
            loader: false,
            showPassword: false,
            requirederror: false,
            erroertext: "",
            roletype: "",
            i_agree: false,
            viewpassword: false,
            inputname: "",
            online: window.navigator.onLine,
            checkedG: false.valueOf,
            token: ""
        }
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    fetchData = async () => {
        const res = await publicIp.v4();
        this.setState({
            ipv4: res
        })
    }
    componentDidMount() {

        this.fetchData()
        if (CookieSetService.get('username') && CookieSetService.get('password')) {
            this.setState({
                Username: CookieSetService.get('username'),
                Password: CookieSetService.get('password'),
                checkedG: CookieSetService.get('checkSatus')
            })
        }
        window.addEventListener('offline', this.handleNetworkChange);
        window.addEventListener('online', this.handleNetworkChange);
    }

    componentWillUnmount() {
        window.removeEventListener('offline', this.handleNetworkChange);
        window.removeEventListener('online', this.handleNetworkChange);
    }
    handleNetworkChange = () => {
        this.setState({ online: window.navigator.onLine });
    }
    login = () => {

        if (Settings.mobilenumber(this.state.Username).status) {
            this.setState({ loader: true });
            let body = {
                username: this.state.Username,
                pwd: this.state.Password,
            }

            if (this.state.Username === '9491381997' && this.state.Password === '12345') {
                window.sessionStorage.setItem('token', true);
                window.sessionStorage.setItem('role', "IsManager");
                this.props.history.push("/Dashboard");


            }
            // if (this.state.online) {
            // axios.post(AUTH + '/signin', body, {
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // }).then(result => {
            //     this.setState({ loader: false })
            //     if (result.status === 200) {

            //         this.setState({ loader: false });
            //         this.setState({ token: result.data.generatedToken })
            //         let mainData = { access_toke: result.data.generatedToken, username: this.state.Username, password: this.state.Password }
            //         localStorage.setItem("token", result.data.generatedToken)
            //         window.sessionStorage.setItem('token', result.data.generatedToken);
            //         window.sessionStorage.setItem('role', result.data.roletype);
            //         localStorage.setItem("username", result.data.username)
            //         localStorage.setItem("role", result.data.roletype)
            //         localStorage.setItem("firstname", result.data.firstname)
            //         localStorage.setItem("company", result.data.company)
            //         localStorage.setItem("companyid", result.data.companyid)
            //         window.sessionStorage.setItem("logopath", result.data.logopath)
            //         window.sessionStorage.setItem("oem", result.data.oem)
            //         window.sessionStorage.setItem("support", result.data.support)
            //         window.sessionStorage.setItem("visitor", result.data.visitor)
            //         window.sessionStorage.setItem("tenant", result.data.tenant)


            //         window.sessionStorage.setItem("supportmobile", result.data.supportmobile)
            //         window.sessionStorage.setItem("supportemail", result.data.supportemail)

            //         Settings.golbalcookiefun(mainData, this.state.checkedG)

            //         let api = HOST + "/userlog/add"
            //         let body = { ipaddress: this.state.ipv4 }
            //         axios.post(api, body, {
            //             headers: {
            //                 "authToken": this.state.token,
            //                 'Accept': 'application/json',
            //                 // 'Content-Type': 'application/json'
            //             }
            //         })
            //         let role = window.sessionStorage.getItem('role')

            //         if (role === "IsTenant") {
            //             this.props.history.push("/vehicle");

            //         } else {
            //             this.props.history.push("/Dashboard");
            //         }
            //     }
            // }).catch(err => {
            //     // if (err.message === "Network Error") {
            //     //     this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
            //     //     setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            //     // }
            //     if (err.response) {
            //         if (err.response.status === 401) {
            //             this.setState({ netWorkError: true, loader: false, erroertext: Settings.Unauthrized().errmessgae })
            //             setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            //         }
            //         else if (err.response.status === 502) {
            //             this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
            //             setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            //         }
            //     }
            // })
            // } else {
            //     this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
            //     setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            // }

        } else {
            this.setState({ loader: false });
            this.setState({ flagError: true, erroertext: Settings.mobilenumber(this.state.Username).errmessgae })
            setTimeout(() => { this.setState({ flagError: false }) }, 3000);
        }


    }
    render() {

        const { classes } = this.props
        return (
            <div className={classes.dialog}>
                <div className={classes.root}>
                    <GridContainer  >
                        <GridItem xs={12} sm={12} md={4}>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} className={classes.root}>
                            <Card >
                                <CardHeader color="primary" >
                                    <h4 className={classes.cardTitleWhite} >{Settings.titleCase("Login")}</h4>
                                    <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Please sign in to continue")}</p>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer  >
                                        <GridItem xs={12} sm={12} md={12} >
                                            {this.state.netWorkError ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                            <CustomInput
                                                labelText="* Mobile Number(10 digits)"
                                                id="Mobile"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        this.setState({ Username: event.target.value })
                                                        if (Settings.mobilenumber(event.target.value).status || !event.target.value) {
                                                            this.setState({ flagError: false, erroertext: "" })
                                                        } else {
                                                            this.setState({ flagError: true, erroertext: Settings.mobilenumber(event.target.value).errmessgae, inputname: Settings.mobilenumber(event.target.value).inputname })
                                                        }
                                                    },
                                                    onInput: (e) => e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10),
                                                    type: "number",
                                                    defaultValue: this.state.Username,
                                                    value: this.state.Username,
                                                    error: this.state.Username === "" ? this.state.requirederror : ""

                                                }}
                                            />
                                            {this.state.requirederror && !this.state.Username ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                            {this.state.flagError && this.state.inputname === "Mobile" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="* Password(min. 3 & max. 20)"
                                                id="Mobile"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        this.setState({ Password: event.target.value })
                                                        if (Settings.PasswordMain(event.target.value).status) {
                                                            this.setState({ flagError: false, erroertext: "" })
                                                        } else {
                                                            this.setState({ flagError: true, erroertext: Settings.PasswordMain(event.target.value).errmessgae, inputname: Settings.PasswordMain(event.target.value).inputname })
                                                        }
                                                    },
                                                    onKeyPress:
                                                        (event) => {
                                                            if ((event.key === 'Enter') && (this.state.Username !== "") && (this.state.Password !== "")) {
                                                                this.login()
                                                            }
                                                        }
                                                    ,
                                                    type: this.state.viewpassword ? "text" : "password",
                                                    defaultValue: this.state.Password,
                                                    value: this.state.Password,
                                                    error: this.state.Password === "" ? this.state.requirederror : "",
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <div style={{ cursor: "pointer" }} onClick={() => {
                                                                this.setState({ viewpassword: !this.state.viewpassword })
                                                            }}>
                                                                {this.state.viewpassword ? <VisibilityIcon className={classes.inputIconsColor} /> : <VisibilityOffIcon className={classes.inputIconsColor} />}
                                                            </div>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                            {this.state.requirederror && !this.state.Password ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                            {this.state.flagError && this.state.inputname === "Password" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <FormControlLabel
                                                control={<GreenCheckbox name="checkedG" checked={this.state.checkedG} onChange={this.handleChange} />}
                                                label="Remember me"

                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter>
                                    <div style={{ cursor: "pointer" }}>
                                        {this.state.Username !== "" && this.state.Password !== "" ? <Button color={"primary"} onClick={this.login}><LockOpenIcon />Sign In</Button> : <Button
                                            onClick={() => {
                                                if (Settings.requireerrors([this.state.Username, this.state.Password]).status === true) {
                                                    this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.Username, this.state.Password]).errmessgae })
                                                    setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                                                }
                                            }}
                                        > <LockIcon />Sign In</Button>}
                                    </div>
                                    <p color="primary" style={{ marginRight: 5, cursor: "pointer", color: "#1877f2" }} onClick={() => {
                                        this.props.history.push('/forgot-password')
                                    }}>{Settings.titleCase("Forgot password?")}</p>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                        </GridItem>
                    </GridContainer>
                    {this.state.loader ? <LoaderComponent /> : ""}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(login);