
import React, { Component } from 'react'
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { withRouter } from "react-router-dom";
import Settings from '../../lprfolders/GlobalConstant/Settings';
import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import InfoIcon from '@material-ui/icons/Info';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Alert from '../../lprfolders/GlobalConstant/Alert'
import axios from 'axios';
import { HOST } from '../../lprfolders/config';
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },

    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};
class Edit extends Component {
    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
        this.state = {
            flagError: false,
            netWorkError: false,
            visitorid: "",
            erroertext: "",
            flatno: "",
            fullname: "",
            mobile: "",
            vehicle: "",
            visitorname: "",
            companyname: "",
            hostname: "",
            hostnameerror: false,
            tomeet: "",
            comments: "",
            inputname: "",
            tc: false,
            bc: false,
            statusid: 0,
            online: window.navigator.onLine,
            tenant: "",
            firstname: "",
            lastname: "",
            mobile: "",
            email: "",
            pwd: ""

        }
    }
    componentDidMount() {
        this.gettenantDetails()
        window.addEventListener('offline', this.handleNetworkChange);
        window.addEventListener('online', this.handleNetworkChange);
    }

    componentWillUnmount() {
        window.removeEventListener('offline', this.handleNetworkChange);
        window.removeEventListener('online', this.handleNetworkChange);
    }
    handleNetworkChange = () => {
        this.setState({ online: window.navigator.onLine });
    }
    add = () => {
        // let count = 1

        // if (this.state.vehicle === "") {
        //     count--
        // }
        // if (this.state.vehicle) {
        //     if (Settings.Vehicle(this.state.vehicle).status) {
        //       count--
        //     }
        //     else {
        //         count++
        //         this.setState({ flagError: true, erroertext: Settings.Vehicle(this.state.vehicle).errmessgae, inputname: Settings.Vehicle(this.state.vehicle).inputname })
        //         setTimeout(() => { this.setState({ flagError: false }) }, 3000);
        //     }
        // }
        // if (Settings.PersonName(this.state.visitorname).status) {
        //     if (Settings.mobilenumber(this.state.mobile).status) {
        //             if (Settings.Comments(this.state.tomeet).status) {



        //             } else {
        //                 count++
        //                 this.setState({ flagError: true, erroertext: Settings.Comments(this.state.tomeet).errmessgae, inputname: Settings.Comments(this.state.tomeet).inputname })
        //                 setTimeout(() => { this.setState({ flagError: false }) }, 3000);
        //             }

        //         } 

        //      else {
        //         count++
        //         this.setState({ flagError: true, erroertext: Settings.mobilenumber(this.state.mobile).errmessgae, inputname: Settings.mobilenumber(this.state.mobile).inputname })
        //         setTimeout(() => { this.setState({ flagError: false }) }, 3000);
        //     }
        // }
        // else {
        //     count++
        //     this.setState({ hostnameerror: false, flagError: true, erroertext: Settings.PersonName(this.state.visitorname).errmessgae, inputname: Settings.PersonName(this.state.visitorname).inputname })
        //     setTimeout(() => { this.setState({ flagError: false }) }, 3000);
        // }
        // if (count === 0) {
        if (this.state.online) {

            let api = HOST + "/tenant/update"
            let body = {
                tenantid: this.props.location.state.propdata.tenantid,
                tenant: this.state.tenant,
                mobile: this.state.mobile,
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email,
                pwd: this.state.pwd
            }
             this.setState({ loader: true })

            axios.put(api, body, {
                headers: {
                    "authToken": this.token,
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json'
                }
            }).then(res => {
                if (res.status === 201) {
                    this.setState({ loader: false })
                    this.showNotification("tc")
                    setTimeout(() => { this.props.history.push('/tenant') }, 2000);
                }
            }).catch(err => {
                if (err.message === "Network Error") {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
                if (err.response) {
                    if (err.response.data.statusid === 409) {
                        this.setState({ flagError: true, loader: false, erroertext: Settings.CompnayName(409).errmessgae, inputname: Settings.CompnayName(409).inputname })
                        setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                    }
                    if (err.response.status === 502) {
                        this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                    }
                }
            })
        }
        else {
            this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
            setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
        }
        //  }

    }
    showNotification = place => {
        switch (place) {
            case "tc":
                if (!this.state.tc) {
                    this.setState({ tc: true, erroertext: Alert.upadateSuccess });
                    setTimeout(() => { this.setState({ tc: false }) }, 4000)
                }
                break;
            case "bc":
                if (!this.state.bc) {
                    this.setState({ bc: true });
                    setTimeout(() => { this.setState({ bc: false }) }, 4000)
                }
                break;
            default:
                break;
        }
    };

    gettenantDetails = () => {
        this.setState({ loader: true })
        let api2 = HOST + "/tenant/get/" + this.props.location.state.propdata.tenantid
        axios.get(api2, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            this.setState({
                tenant: response.data.tenant,
                firstname: response.data.firstname,
                lastname: response.data.lastname,
                mobile: response.data.mobile,
                email: response.data.email,
                pwd: response.data.pwd,


            })
            this.setState({ loader: false })

        }).catch(err => {
            if (err.message === "Network Error") {
                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            }
            if (err.response) {
                if (err.response.status === 502) {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
            }
        })
    }
    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <GridContainer>
                    {this.state.tc ? <Snackbar
                        place="tc"
                        color="success"
                        icon={ThumbUpIcon}
                        message={" Tenant " + this.state.erroertext}
                        open={this.state.tc}
                        closeNotification={() => this.setState({ tc: false })}
                        close
                    /> : ""}
                    <GridItem xs={12} sm={12} md={2}>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Edit Tenant record")}</h4>
                                <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Update details pertaining to the tenant")}</p>
                            </CardHeader>
                            <CardBody>
                                {this.state.netWorkError ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={this.state.erroertext}
                                    // close
                                    color="danger"
                                /> : ""}
                                {this.state.loader ? <LoaderComponent /> : ""}
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* Company Name"
                                            id="tenant"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    this.setState({ tenantName: event.target.value })

                                                    if (Settings.FirstName(event.target.value).status || event.target.value === "") {
                                                        this.setState({ flagError: false, erroertext: "" })
                                                    } else {
                                                        this.setState({ flagError: true, erroertext: Settings.CompnayName(event.target.value).errmessgae, inputname: Settings.CompnayName(event.target.value).inputname })
                                                    }
                                                },
                                                value: this.state.tenant,
                                                type: "text",
                                                error: (this.state.tenantName === "" && this.state.requirederror) || (this.state.flagError && this.state.inputname) === "CompanyName" ? true : false
                                            }}
                                        />
                                        {(this.state.requirederror && !this.state.companyname) ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "CompanyName" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* First Name(min. 3 & max. 8 characters)"
                                            id="First Name"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    this.setState({ firstname: event.target.value })

                                                    if (Settings.FirstName(event.target.value).status || event.target.value === "") {
                                                        this.setState({ flagError: false, erroertext: "" })
                                                    } else {
                                                        this.setState({ flagError: true, erroertext: Settings.FirstName(event.target.value).errmessgae, inputname: Settings.FirstName(event.target.value).inputname })
                                                    }
                                                },
                                                type: "text",
                                                value: this.state.firstname,
                                                error: (this.state.firstname === "" && this.state.requirederror) || (this.state.flagError && this.state.inputname) === "FirstName" ? true : false
                                            }}
                                        />
                                        {(this.state.requirederror && !this.state.firstname) ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "FirstName" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* Last Name(min. 3 & max. 8 characters)"
                                            id="Last Name"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    this.setState({ lastname: event.target.value })
                                                    if (Settings.LastName(event.target.value).status || event.target.value === "") {
                                                        this.setState({ flagError: false, erroertext: "" })
                                                    } else {
                                                        this.setState({ flagError: true, erroertext: Settings.LastName(event.target.value).errmessgae, inputname: Settings.LastName(event.target.value).inputname })
                                                    }
                                                },
                                                type: "text",
                                                value: this.state.lastname,
                                                error: (this.state.lastname === "" && this.state.requirederror) || (this.state.flagError && this.state.inputname) === "LastName" ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.lastname ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "LastName" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* Mobile Number(10 digits)"
                                            id="mobile"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    this.setState({ mobile: event.target.value })
                                                    if (Settings.mobilenumber(event.target.value).status || !event.target.value) {
                                                        this.setState({ flagError: false, erroertext: "" })
                                                    } else {
                                                        this.setState({ flagError: true, erroertext: Settings.mobilenumber(event.target.value).errmessgae, inputname: Settings.mobilenumber(event.target.value).inputname })
                                                    }
                                                },
                                                disabled: true,

                                                value: this.state.mobile,
                                                onInput: (e) => e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10),
                                                type: "number",
                                                error: (this.state.flagError && this.state.inputname === "Mobile") || (this.state.requirederror && this.state.mobile === "") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.mobile ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "Mobile" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>


                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Email(Ex. info@gaido.in)"
                                            id="Email"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    this.setState({ email: event.target.value })
                                                    if (Settings.Email(event.target.value).status || event.target.value === "") {
                                                        this.setState({ flagError: false, erroertext: "" })
                                                    } else {
                                                        this.setState({ flagError: true, erroertext: Settings.Email(event.target.value).errmessgae, inputname: Settings.Email(event.target.value).inputname })
                                                    }


                                                },
                                                type: "text",
                                                value: this.state.email,
                                                error: this.state.flagError && this.state.inputname === "Email" ? true : false
                                            }}
                                        />
                                        {this.state.flagError && this.state.inputname === "Email" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                            <CardFooter>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={5}>
                                        {this.state.tenant !== "" && this.state.mobile !== ""  && this.state.firstname !== "" && this.state.lastname !== "" && this.state.pwd !== "" ?
                                            <Button color="primary" onClick={this.add}> <DoneOutlineIcon />save<span style={{ display: "inline-block", width: "21px" }}></span></Button> :
                                            <Button onClick={() => {
                                                if (Settings.requireerrors([this.state.tenant, this.state.mobile,this.state.lastname,this.state.firstname,this.state.pwd,this.state.mail]).status === true) {
                                                    this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.visitorname, this.state.companyname]).errmessgae })
                                                    setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                                                }
                                            }}> <DoneOutlineIcon />save<span style={{ display: "inline-block", width: "21px" }}></span></Button>
                                        }
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={1}>
                                        <Button color="white" onClick={() => {
                                            this.props.history.push({
                                                pathname: '/tenant/view', state: {
                                                    propdata: this.props.location.state.propdata
                                                }
                                            })
                                        }}><CancelPresentationIcon />Cancel</Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                    </GridItem>
                                </GridContainer>
                            </CardFooter>
                        </Card>
                    </GridItem>

                </GridContainer>

            </div>
        );
    }
}
export default withRouter((withStyles(styles)(Edit)));


