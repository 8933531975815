
import React, { Component } from 'react'
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { HOST } from '../../lprfolders/config';
import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
import Settings from '../../lprfolders/GlobalConstant/Settings';
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import InfoIcon from '@material-ui/icons/Info';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Alert from '../../lprfolders/GlobalConstant/Alert'
import BackupIcon from '@material-ui/icons/Backup';
import { Icon } from '@iconify/react';
import fileExcel from '@iconify/icons-mdi/file-excel';
import Table from "../../components/Bulktable/BulkTable";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import moment from 'moment';
import XLSX from 'xlsx';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import _ from 'lodash'
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};


class BulkUpload extends Component {
    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
        this.state = {
            companyname: '',
            mobile: '',
            email: '',
            website: '',
            companyurl: '',
            ctype: '',
            results: [],
            flagError3: false,
            loader: false,
            addButton: true,
            facebookurl: "",
            password: "",
            firstname: "",
            lastname: "",
            instagramurl: "",
            imageURL: "",
            twitterurl: "",
            zomatourl: "",
            linkedinurl: "",
            google: "",
            sms: "",
            ratingurl: "",
            selectedFile: '',
            companytype: "",
            companytypesarray: [],
            roletypesarray: [],
            roletype: "",
            success: false,
            requirederror: false,
            flagError: false,
            erroertext: "",
            inputname: "",
            tc: false,
            bc: false,
            imageuploadbutton: false,
            netWorkError: false,
            xlcoloums: ["EMP ID", "VEHICLE NUMBER", "MOBILE NUMBER", "PERSON NAME", "COMMENTS"],
            emptystate: false,
            showtable: false,
            headerror: false,
            data: [],
            mainarray: [],
            errorindex: [],
            BulkUploadButton: false,
            CurrentDate: new Date(),
            Samplepopup: false
        }
    }

    showNotification = place => {
        switch (place) {
            case "tc":
                if (!this.state.tc) {
                    this.setState({ tc: true, erroertext: Alert.Successadd });
                    setTimeout(() => { this.setState({ tc: false }) }, 4000)
                }
                break;
            case "bc":
                if (!this.state.bc) {
                    this.setState({ bc: true });
                    setTimeout(() => { this.setState({ bc: false }) }, 4000)
                }
                break;
            default:
                break;
        }
    };
    export_table_to_csv = (html, filename) => {
        var csv = [];
        var cols = [];
        for (var j = 0; j < this.state.xlcoloums.length; j++) {
            if (this.state.xlcoloums[j].name !== "action") {
                cols.push(this.state.xlcoloums[j])
            }
        }
        csv.push(cols.join(","));
        this.download_csv(csv.join("\n"), filename);
    }
    download_csv = (csv, filename) => {
        var csvFile;
        var downloadLink;
        csvFile = new Blob([csv], { type: "text/csv" });
        downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }
    handleFile(event) {

        let reader = new FileReader();
        let rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname]
            const data = XLSX.utils.sheet_to_json(ws, {
                defval: "", 
            });
            const newArray =data.map(({EMPID, ...rest}) => rest)
            let ex = JSON.parse(JSON.stringify(data).replace(/\s(?=\w+":)/g, ""))
            let stringjson = JSON.parse(JSON.stringify(ex).replace(/\s(?=\w+":)/g, ""))
            let FinalStringJSon = JSON.parse(JSON.stringify(stringjson).replace(/"\s+|\s+"/g, '"'))
            var trimmed = JSON.stringify(FinalStringJSon, (key, value) => {
                if (typeof value === 'string') {
                    return value.trim();
                }
                return value;
            });
            let InsertData = JSON.parse(trimmed)
            if (data.length === 0) {
                this.setState({ emptystate: true, showtable: false })
                setTimeout(() => { this.setState({ emptystate: false }) }, 6000);
            } else {
                let finalarray = []
                InsertData.filter(obj => {
                    return finalarray.push(Object.assign({ vehicle: String(obj.VEHICLENUMBER).replace(/\s+/g, ''), flatno: String(obj.EMPID).replace(/\s+/g, ''), fullname: String(obj.PERSONNAME).replace(/\s+/g, ''), mobile: String(obj.MOBILENUMBER).replace(/\s+/g, ''), comments: String(obj.COMMENTS).replace(/\s(?=\s)/g, '') }))
                })
                this.setState({ emptystate: false, showtable: true, mainarray: finalarray })
                if (Settings.BulkuploadHedearCheck(data, this.state.xlcoloums).status) {
                    if (Settings.BulkuploadDataCheck(InsertData, this.state.xlcoloums).status) {
                        this.setState({ BulkUploadButton: true })
                    } else {
                        this.setState({
                            errorindex: Settings.BulkuploadDataCheck(InsertData, this.state.xlcoloums).errorindex,
                            flagError: true, loader: false, showtable: true, erroertext: Settings.BulkuploadDataCheck(InsertData, this.state.xlcoloums).errmessgae,
                        })
                        setTimeout(() => { this.setState({ flagError: false }) }, 6000);
                    }
                } else {
                    this.setState({ flagError: true, headerror: true, mainarray: [], showtable: true, loader: false, erroertext: Settings.BulkuploadHedearCheck(data, this.state.xlcoloums).errmessgae })
                    setTimeout(() => { this.setState({ flagError: false }) }, 10000);
                }
            }
        }
        if (rABS) reader.readAsBinaryString(event.target.files[0])
        else reader.readAsArrayBuffer(event.target.files[0])
    }
    bulkupload = () => {
        let api = HOST + "/vehicle/bulk"
        this.setState({ loader: true })
        axios.post(api, this.state.mainarray, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
            }
        }).then(res => {
            if (res.status === 201) {
                this.setState({ loader: false })
                this.showNotification("tc")
                setTimeout(() => { this.props.history.push('/vehicle') }, 2000);
            }
        }).catch(err => {
            if (err.message === "Network Error") {
                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
            }
            if (err.response) {
                if (err.response.status === 502) {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
            }
        })

    }
    showNotification = place => {
        switch (place) {
            case "tc":
                if (!this.state.tc) {
                    this.setState({ tc: true, erroertext: Alert.Successadd });
                    setTimeout(() => { this.setState({ tc: false }) }, 4000)
                }
                break;
            case "bc":
                if (!this.state.bc) {
                    this.setState({ bc: true });
                    setTimeout(() => { this.setState({ bc: false }) }, 4000)
                }
                break;
            default:
                break;
        }
    };

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                {this.state.tc ? <Snackbar
                    place="tc"
                    color="success"
                    icon={ThumbUpIcon}
                    message={" Vehicles " + this.state.erroertext}
                    open={this.state.tc}
                    closeNotification={() => this.setState({ tc: false })}
                    close
                /> : ""}
                <Dialog
                    // fullScreen={fullScreen}
                    open={this.state.Samplepopup}
                    onClose={() => {
                        this.setState({ Samplepopup: false })
                    }}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title" onClose={() => {
                        this.setState({ Samplepopup: false })
                    }}>
                        {"Sample Guideline Rules"}
                        <IconButton aria-label="close" style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                        }} onClick={() => {
                            this.setState({ Samplepopup: false })
                        }}>
                            <CancelOutlinedIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ overflow: "hidden" }}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <p><ArrowRightAltIcon style={{marginBottom:-7,color:"#00acc1"}} /> EMP ID Special characters are not allowed.</p>
                                    <p><ArrowRightAltIcon style={{marginBottom:-7,color:"#00acc1"}} /> EMP ID should be minimum 1 and maximum 10 digits.</p>
                                    <p><ArrowRightAltIcon style={{marginBottom:-7,color:"#00acc1"}} /> VEHICLE NUMBER Special characters are not allowed.</p>
                                    <p><ArrowRightAltIcon style={{marginBottom:-7,color:"#00acc1"}} /> VEHICLE NUMBER should be minimum 5 and maximum 10 digits.</p>
                                    <p><ArrowRightAltIcon style={{marginBottom:-7,color:"#00acc1"}} /> Duplicate VEHICLE NUMBER are not allowed</p>
                                    <p><ArrowRightAltIcon style={{marginBottom:-7,color:"#00acc1"}} /> MOBILE must be 10 digits.</p>                                    
                                    <p><ArrowRightAltIcon style={{marginBottom:-7,color:"#00acc1"}} /> PERSON NAME Only characters are allowed.</p>
                                    <p><ArrowRightAltIcon style={{marginBottom:-7,color:"#00acc1"}} /> PERSON NAME should be minimum 3 and maximum 20 characters.</p>
                                    <p><ArrowRightAltIcon style={{marginBottom:-7,color:"#00acc1"}} /> COMMENTS should be maximum 25 characters.</p>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </DialogContent>
                </Dialog>
                <GridContainer>

                    <GridItem xs={12} sm={12} md={5}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Bulk Upload")}</h4>
                                <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Add multiple vehicles using excel template")}</p>
                            </CardHeader>
                            <CardFooter>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Button color="info" onClick={() => this.export_table_to_csv("", "Add Vehicles.csv")}><Icon icon={fileExcel} />Download Template</Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <input id="file" hidden type="file" accept=".csv,.xls,.xlsx" onChange={(event) => {
                                            this.handleFile(event)
                                            this.setState({ showtable: false, mainarray: [], BulkUploadButton: false })
                                            event.target.value = null;
                                        }
                                        } />
                                        <Button color="primary" onClick={() => {
                                            this.setState({ showtable: false, mainarray: [], BulkUploadButton: false })
                                            document.getElementById("file").click()
                                        }}><span style={{ display: "inline-block", width: "28px" }}></span><BackupIcon />Upload File<span style={{ display: "inline-block", width: "28px" }}></span></Button>

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Button color="info" onClick={() => this.setState({Samplepopup:true})}><span style={{ display: "inline-block", width: "12px" }}></span><InfoIcon /> Sample Guidelines </Button>
                                    </GridItem>
                                </GridContainer>
                            </CardFooter>
                        </Card>
                    </GridItem>

                </GridContainer>
                {this.state.emptystate ? <SnackbarContent
                    icon={InfoIcon}
                    message={"No records found"}
                    // close
                    color="danger"
                /> : ""}
                <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>

                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>

                    </GridItem>
                    <GridItem xs={12} sm={6} md={2}>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={2}>
                        {this.state.BulkUploadButton ? <div style={{ width: "100%", display: "flex", alignItems: 'center', justifyContent: "center" }}>
                            <Button color="primary" onClick={() => {
                                this.bulkupload()
                            }}><span style={{ display: "inline-block", width: "15px" }}></span><DoneOutlineIcon />Save<span style={{ display: "inline-block", width: "15px" }}></span></Button>
                        </div> : ""}
                    </GridItem>
                </GridContainer>

                {this.state.showtable ?
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Vehicle records")}</h4>
                                    <p className={classes.cardCategoryWhite}>
                                        {Settings.capitalizeFLetter("Vehicle details as on")} {moment(this.state.CurrentDate).format("DD-MM-YYYY")}
                                    </p>
                                </CardHeader>
                                {this.state.emptystate ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={"No records found"}
                                    // close
                                    color="danger"
                                /> : ""}
                                {this.state.netWorkError ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={this.state.erroertext}
                                    // close
                                    color="danger"
                                /> : ""}
                                <CardBody>
                                    {this.state.loader ? <LoaderComponent /> : ""}
                                    <Table
                                        tableHeaderColor="success"
                                        tableHead={
                                            [
                                                {
                                                    name: "EMP ID",
                                                    prop: "flatno"
                                                },
                                                {
                                                    name: "VEHICLE NUMBER",
                                                    prop: "vehicle"
                                                },
                                                {
                                                    name: "MOBILE NUMBER",
                                                    prop: "mobile"
                                                },
                                                {
                                                    name: "PERSON NAME",
                                                    prop: "fullname"
                                                },
                                                {
                                                    name: "COMMENTS",
                                                    prop: "comments"
                                                }]}
                                        tableData={this.state.mainarray}
                                        routename="vehicle"
                                        erroertext={this.state.erroertext}
                                        flagError={this.state.flagError}
                                        headerror={this.state.headerror}
                                        errorindex={this.state.errorindex}
                                    />
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                        </GridItem>
                    </GridContainer> : ""}
            </div>
        );
    }
}

export default withRouter((withStyles(styles)(BulkUpload)));

