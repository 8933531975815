import { withStyles } from "@material-ui/core/styles";
// import Icon from "@material-ui/core/Icon";

import { withRouter } from "react-router-dom";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "Grids/Tenant";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import React, { Component } from 'react';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Button from "components/CustomButtons/Button.js";
import AddBoxIcon from '@material-ui/icons/AddBox';
import moment from 'moment';
import Settings from '../../lprfolders/GlobalConstant/Settings'
import LockIcon from '@material-ui/icons/Lock';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem('token');
    this.user = localStorage.getItem('username');
    this.roletype = window.sessionStorage.getItem('role')
    localStorage.getItem('user', 'username');
    this.state = {
      vehicles: [],
      example: [],
      vechicle: {},
      flagError: false,
      loader: false,
      company: '',
      mobile: '',
      email: '',
      ratingurl: '',
      website: '',
      icon: false,
      toggle: false,
      toggle1: false,
      searchInput: "",
      empty: false,
      first: 1,
      last: 10,
      countrecords: 0,
      pagecontain: 10,
      arrowfront: false,
      arrowback: false,
      page: [],
      data: false,
      previous: false,
      new: false,
      current: 10,
      currentpage: 1,
      navcolor: false,
      navcolor1: false,
      recordnumber: [],
      active: true,
      inactive: true,
      simple: [],
      finalrecord: [],
      deleteid: "",
      CurrentDate: new Date(),

    }
  }
  onTagsChange = (event, values) => {
    this.setState({
      tags: values
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12} >
            <GridContainer>
              <GridItem xs={12} sm={6} md={6} lg={8}>
                
              </GridItem>
              <GridItem xs={12} sm={3} md={3} lg={2} >
              {/* <div style={{ width: "100%", display: "flex", alignItems: 'center', justifyContent: 'center', }}>
              <Button color="info" onClick={() => {
                // this.props.history.push('/tenant/add')
              }}><LockIcon />Tenant Access</Button>
            </div> */}
              </GridItem>
              <GridItem xs={12} sm={3} md={3} lg={2} >
               <div style={{ width: "100%", display: "flex", alignItems: 'center', justifyContent: 'center', }}>
              <Button color="primary" onClick={() => {
                this.props.history.push('/tenant/add')
              }}><AddBoxIcon /> Add  Tenant</Button>
            </div>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Tenant records")}</h4>
                <p className={classes.cardCategoryWhite}>
                {Settings.capitalizeFLetter("Tenant details as on")} {moment(this.state.CurrentDate).format("DD-MM-YYYY")}
              </p>
              </CardHeader>
              <CardBody>
                <Table
                  id="users"
                  tableHeaderColor="success"
                  tableHead={[
                    {
                      name: "S.No",
                      prop: "s.no"
                    },
                    {
                      name: "Company Name",
                      prop: "tenant"
                    },
                    {
                      name: "First Name",
                      prop: "firstname"
                    },
                    {
                      name: "Last Name",
                      prop: "lastname"
                    },
                    {
                      name: "Mobile ",
                      prop: "mobile"
                    },
                   
                    // {
                    //   name: "Email",
                    //   prop: "email"
                    // },
                    {
                      name: "Created On",
                      prop: "createdon"
                    },
                    {
                      name: "Action",
                      prop: "action"
                    }]}
                  routename="tenant"
                // tableHead={["S.No", "Company",  "User Name", "Role", "Name", "Email", "Match","Created On","Action"]}
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withRouter((withStyles(styles)(Dashboard)));
