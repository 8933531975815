import React, { Component } from 'react';
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import WarningIcon from '@material-ui/icons/Warning';
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    dialog: {
        // backgroundImage: "url(http://lpr.gaido.in/static/media/bulkcar.8585b4d8.jpg)",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: "100%",
        overflowX: "hidden",
        overflowy: "auto",

    },
    root: {
        width: "100%",
        minHeight: 330,
        position: "relative",
        top: "50%",
        left: "50%",
        // webkitTransform:" translateY(-50%) translateX(-50%)",
        transform: "translateY(-50%) translateX(-50%)",
    }
};
class login extends Component {
    render() {

        const { classes } = this.props
        return (
            <div className={classes.dialog}>
                <div className={classes.root}>

                    <GridContainer  >
                        <GridItem xs={12} sm={12} md={4}>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} className={classes.root}>
                            <Card >
                                <CardHeader color="danger">
                                    <h4 className={classes.cardTitleWhite}>Invalid Login Credentials</h4>
                                    <p className={classes.cardCategoryWhite}>Please sign in to continue</p>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer >
                                        <GridItem xs={12} sm={12} md={12} >
                                       {/* <SnackbarContent
                                                icon={PanToolOutlinedIcon}
                                                message={"Access denied to due to invalid login credentials"}
                                                // close
                                                color="danger"
                                            /> */}
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                           <SnackbarContent
                                                icon={WarningIcon}
                                                message={"You do not have permission to view this resource using the credentials that you supplied."}
                                                // close
                                                color="warning"
                                            />                                           
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter>
                                    <div style={{ cursor: "pointer" }}>
                                       <Button color="primary" onClick={()=>this.props.history.push('/')}>Sign In</Button>
                                    </div>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(login);