import firebase from "firebase/compat";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDkHxYykkQ-8Tw9yprmNOA-MEZWQRp27gc",
  authDomain: "demoapplication-faf41.firebaseapp.com",
  projectId: "demoapplication-faf41",
  storageBucket: "demoapplication-faf41.appspot.com",
  messagingSenderId: "474505457964",
  appId: "1:474505457964:web:4af72a3e70f3c18e37bc89",
  measurementId: "G-ZMKM04KKFL"
};


const firebaseApp = firebase.initializeApp(firebaseConfig);
const database = firebase.firestore();
  
export default database