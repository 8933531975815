// import { initializeApp } from "firebase/app";
// import { getDatabase } from "firebase/database";


// import "firebase/compat/database";
import { getFirestore } from "firebase/firestore";
// const firebaseConfig = {
//   apiKey: "AIzaSyAhOwvxdutgmFFSWzJPfAjUagxWN-D0Xyo",
//   authDomain: "display-7d042.firebaseapp.com",
//   databaseURL: "https://display-7d042-default-rtdb.firebaseio.com",
//   projectId: "display-7d042",
//   storageBucket: "display-7d042.appspot.com",
//   messagingSenderId: "458341892955",
//   appId: "1:458341892955:web:b6072a1b923b752988d07e"
// };

// const app = initializeApp(firebaseConfig);
// export const db = getFirestore(app);
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDkHxYykkQ-8Tw9yprmNOA-MEZWQRp27gc",
  authDomain: "demoapplication-faf41.firebaseapp.com",
  projectId: "demoapplication-faf41",
  storageBucket: "demoapplication-faf41.appspot.com",
  messagingSenderId: "474505457964",
  appId: "1:474505457964:web:4af72a3e70f3c18e37bc89",
  measurementId: "G-ZMKM04KKFL"
};
const app=0;
if (!firebase.apps.length) {
  app=firebase.initializeApp(firebaseConfig)
}


export const db = getFirestore(app);
