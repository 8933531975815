import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// core components
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import CommentIcon from '@material-ui/icons/Comment';
import axios from 'axios';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import Search from "@material-ui/icons/Search";
import headerstyles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import CustomInput from "components/serachinput/Serachinput";
import Button from "components/CustomButtons/Button.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { Icon, } from '@iconify/react';
import microsoftExcel from '@iconify/icons-mdi/microsoft-excel';
import TablePagination from '@material-ui/core/TablePagination';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import { withRouter } from "react-router-dom";
import InfoIcon from '@material-ui/icons/Info';
import { HOST } from 'lprfolders/config';
import LoaderComponent from 'lprfolders/GlobalConstant/LoaderComponent'
import Alert from 'lprfolders/GlobalConstant/Alert'
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Snackbar from "components/Snackbar/Snackbar.js";
import Settings from 'lprfolders/GlobalConstant/Settings';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CropFreeIcon from '@material-ui/icons/CropFree';
import { Checkbox, TableFooter } from '@material-ui/core';
import ConfirmDialog from 'lprfolders/GlobalConstant/PopUp';
import { collection, getDocs } from 'firebase/firestore';
import { db } from "../firebaseConfig";

const previewStyle = {
  height: 300,
  width: 300,
}


const marignstyles = {
  root: {
    margin: 0,
    width: '100%',
  }
}
const theme = createMuiTheme({
  overrides: {
    MuiTableRow: {
      root: {
        //for the body
        height: "100%"
      },
      head: {
        //for the head
        height: "100%"
      },
      activeSortIcon: {
        opacity: 1,
      },
      // Half visible for inactive icons
      inactiveSortIcon: {
        display: 'none',
      },
      icon: {
        display: 'none',
      },
      active: {
        '& $icon': {
          display: 'inline',
        },
      },
      footer: {
        "& > td > div": {
          height: 30,
          minHeight: 30
        },
        backgroundColor: "grey",
        height: 30
      }
    }
  }
});
const useStyles = makeStyles(styles, headerstyles, marignstyles, theme);

function CustomTable(props) {
  const initialValues = {
    lpr: "",
    entry: "",
    name: "",
    mobile: "",
    toMeet: ""

  }
  const usersCollectionRef = collection(db, "databasevehicle");

  const token = localStorage.getItem('token');
  const classes = useStyles();
  const [TableMaindata, setData] = React.useState([])
  const finalCount = React.useRef([])
  const [open, setOpen] = React.useState(false)
  // const [openForShareActivityConfimr, setopenForShareActivityConfimr] = React.useState(false)
  const [openForShareActivity, setOpenForShareActivity] = React.useState(false)
  const [ShareActivityData, setShareActivityData] = React.useState(null)
  const [loader, setLoader] = React.useState(false)
  const [item, setItem] = React.useState("")
  const [tenant, setTenant] = React.useState("")

  const [videostate, SetVideoState] = React.useState(false)
  const [filterText, setFilterText] = React.useState("");

  const [openApproval, setOpenApproval] = React.useState(false);
  const [checked, setChecked] = React.useState(false)

  const [vehicleIds, setVehicleIds] = React.useState([])
  const [MasterChecked, setMasterChecked] = React.useState(false);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [netWorkError, setnetWorkError] = React.useState(false)
  const [erroertext, seterroertext] = React.useState("");
  const [deleteitem, SetdeleteItem] = React.useState("");
  const [ActivityItem, SetAcivityItem] = React.useState(false);
  const [ModalForAsk, SetModalForAsk] = React.useState("");
  const [tl, setTL] = React.useState(false);
  const [tc, setTC] = React.useState(false);
  const [tcc, setTCC] = React.useState(false);
  const [tr, setTR] = React.useState(false);
  const [bl, setBL] = React.useState(false);
  const [bc, setBC] = React.useState(false);
  const [br, setBR] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [Online, setOnline] = React.useState(window.navigator.onLine)
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { tableHead, tableHeaderColor, routename } = props;
  const indexOfLastPost = page * rowsPerPage;
  const getVehiclesDetails = () => {
    let api;


    api = HOST + '/' + routename + '/getall'
    setLoader(true)
    axios.get(api, {
      headers: {
        "authToken": token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(response => {
      if (response.data !== "") {
        setLoader(false)
        setData(response.data)
      } else {
        setData([])
        setLoader(false)
        showNotification("tcc")
        seterroertext("No records found")
      }
    }).catch((err) => {
      if (err.message === "Network Error") {
        setLoader(false)
        setnetWorkError(true)
        seterroertext(Settings.netWorkError().errmessgae)
        setTimeout(() => { setnetWorkError(false) }, 3000)
      }
      if (err.response) {
        if (err.response.status === 502) {
          setLoader(false)
          setnetWorkError(true)
          seterroertext(Settings.Server().errmessgae)
          setTimeout(() => { setnetWorkError(false) }, 3000)
        }
      }
    })


  }

  const showNotification = place => {
    switch (place) {
      case "tl":
        if (!tl) {
          setTL(true);
          setTimeout(function () {
            setTL(false);
          }, 6000);
        }
        break;
      case "tc":
        if (!tc) {
          setTC(true);
          setTimeout(function () {
            setTC(false);
          }, 6000);
        }
        break;
      case "tcc":
        if (!tcc) {
          setTCC(true);
          setTimeout(function () {
            setTCC(false);
          }, 6000);
        }
        break;
      case "tr":
        if (!tr) {
          setTR(true);
          setTimeout(function () {
            setTR(false);
          }, 6000);
        }
        break;
      case "bl":
        if (!bl) {
          setBL(true);
          setTimeout(function () {
            setBL(false);
          }, 6000);
        }
        break;
      case "bc":
        if (!bc) {
          setBC(true);
          setTimeout(function () {
            setBC(false);
          }, 6000);
        }
        break;
      case "br":
        if (!br) {
          setBR(true);
          setTimeout(function () {
            setBR(false);
          }, 6000);
        }
        break;
      default:
        break;
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const DeleteData = () => {
    if (Online) {
      setLoader(true)
      setOpen(false)
      axios.delete(HOST + '/' + routename + '/delete/' + item, {
        headers: {
          "authToken": token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        seterroertext(String(routename).charAt(0).toUpperCase() + String(routename).slice(1) + "  " + Alert.deletesucces)
        showNotification("tc")
        // if (routename === "visitor") {
        //   props.vehicleCountfun()
        // }
        getVehiclesDetails()
        setLoader(false)
        window.location.reload()
      }).catch(err => {
        if (err.message === "Network Error") {
          setLoader(false)
          setnetWorkError(true)
          seterroertext(Settings.netWorkError().errmessgae)
          setTimeout(() => { setnetWorkError(false) }, 3000)
        }
        if (err.response) {
          if (err.response.status === 502) {
            setLoader(false)
            setnetWorkError(true)
            seterroertext(Settings.Server().errmessgae)
            setTimeout(() => { setnetWorkError(false) }, 3000)
          }
        }
      })
    }
    else {
      setLoader(false)
      setnetWorkError(true)
      seterroertext(Settings.netWorkError().errmessgae)
      setTimeout(() => { setnetWorkError(false) }, 3000)
    }
  }
  const DeleteDataActivity = () => {
    if (Online) {

      setLoader(true)
      SetAcivityItem(false)
      debugger
      axios.delete(HOST + '/' + routename + '/delete/' + deleteitem, {
        headers: {
          "authToken": token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        getVehiclesDetails()
        seterroertext(String(routename).charAt(0).toUpperCase() + String(routename).slice(1) + "  " + Alert.deletesucces)
        showNotification("tcc")
        setLoader(false)
      }).catch(err => {
        if (err.message === "Network Error") {
          setLoader(false)
          setnetWorkError(true)
          seterroertext(Settings.netWorkError().errmessgae)
          setTimeout(() => { setnetWorkError(false) }, 3000)
        }
        if (err.response) {
          if (err.response.status === 502) {
            setLoader(false)
            setnetWorkError(true)
            seterroertext(Settings.Server().errmessgae)
            setTimeout(() => { setnetWorkError(false) }, 3000)
          }
        }
      })
    }
    else {
      setLoader(false)
      setnetWorkError(true)
      seterroertext(Settings.netWorkError().errmessgae)
      setTimeout(() => { setnetWorkError(false) }, 3000)
    }
  }


  const updateTenantApproval = (item) => {
    if (Online) {
      axios.put(HOST + '/' + routename + '/approvedall/', item, {
        headers: {
          "authToken": token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        showNotification("tc")
        seterroertext(String(routename).charAt(0).toUpperCase() + String(routename).slice(1) + " status " + Alert.upadateSuccess)
        setLoader(false)
        window.location.reload()
      }).catch(err => {
        if (err.message === "Network Error") {
          setLoader(false)
          setnetWorkError(true)
          seterroertext(Settings.netWorkError().errmessgae)
          setTimeout(() => { setnetWorkError(false) }, 3000)
        }
        if (err.response) {
          if (err.response.status === 502) {
            setLoader(false)
            setnetWorkError(true)
            seterroertext(Settings.Server().errmessgae)
            setTimeout(() => { setnetWorkError(false) }, 3000)
          }
        }
      })
    }
    else {
      setLoader(false)
      setnetWorkError(true)
      seterroertext(Settings.netWorkError().errmessgae)
      setTimeout(() => { setnetWorkError(false) }, 3000)
    }
  }


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const export_table_to_csv = (html, filename) => {
    var csv = [];
    var cols = [];
    for (var j = 0; j < tableHead.length; j++) {
      if (tableHead[j].name !== "Action" && tableHead[j].name !== "Vehicle Image" && tableHead[j].name !== "Visitor") {
        cols.push(tableHead[j].name)
      }
    }
    csv.push(cols.join(","));
    TableMaindata.forEach((x, i) => {
      var row = []
      tableHead.forEach((y, k) => {
        // if (tableHead[y].name !== "action") {
        if (y.prop === "isactive" && x[y.prop]) {
          row.push(x[y.prop])
        } else {
          if (y.prop === "s.no") {
            row.push(i + 1)
          } else {
            row.push(x[y.prop])
          }
        }
        // }
      })
      csv.push(row.join(","));
    })
    download_csv(csv.join("\n"), filename);
  }
  const download_csv = (csv, filename) => {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  const handleCheck = (e, item) => {
    let tempList = TableMaindata;
    if (e.target.checked) {
      tempList.map((user) => {
        if (user.vehicleid === item.vehicleid) {
          let val = e.target.checked
          if (vehicleIds.map((h) => h.vehicleid).includes(user.vehicleid) !== true) {
            const obj = { 'vehicleid': user.vehicleid };
            vehicleIds.push(obj)
          }
          setChecked(!checked)
        }

      }
      );
    } else {
      tempList.map((user, i) => {

        if (user.vehicleid === item.vehicleid) {
          let val = e.target.checked
          if (vehicleIds.map((h) => h.vehicleid).includes(user.vehicleid) === true) {

            vehicleIds.map((h, i) => {

              if (h.vehicleid === user.vehicleid) {
                vehicleIds.splice(i, 1)

              }

            })

          }


          setChecked(!checked)
        }

      }


      );
    }

    const totalItems = TableMaindata.length - TableMaindata.filter((e) => e.isapproved).length;
    const totalCheckedItems = vehicleIds.length;


    setMasterChecked(totalItems === totalCheckedItems)


  }


  const row = (x, i, headers) => (

    <>
      {headers.map((y, k) => {

        if (y.prop === "isactivity" && x[y.prop]) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <Button color="primary">Yes</Button>
            </TableCell>
          )
        }
        else if (y.prop === "isactivity" && !x[y.prop]) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <Button color="info" onClick={() => {
                setOpenForShareActivity(true)
                setShareActivityData(x)
                SetVideoState(false)
              }}>
                <Tooltip title={"Scan QR to Share"} placement="right">
                  <CropFreeIcon />
                </Tooltip>
              </Button>
            </TableCell>
          )
        }
        else if (y.prop === "db_match" && x[y.prop]) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <Tooltip title={"Match"} placement="right">
                <FiberManualRecordRoundedIcon style={{ color: "green" }} />
              </Tooltip>
            </TableCell>
          )
        }

        else if (y.prop === "db_match" && !x[y.prop]) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <Tooltip title={"No Match"} placement="right">
                <FiberManualRecordRoundedIcon style={{ color: "red" }} />
              </Tooltip>
            </TableCell>
          )
        }
        else if (y.prop === "isactive" && x[y.prop]) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <FiberManualRecordRoundedIcon style={{ color: "green" }} />
            </TableCell>
          )
        }
        else if (y.prop === "isactive" && !x[y.prop]) {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <FiberManualRecordRoundedIcon style={{ color: "red" }} />
            </TableCell>
          )
        }
        else if (y.prop === "action" && routename !== "vehicle") {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <div style={{ display: "flex" }}>
                <Button color="info" aria-label="view" justIcon round onClick={() => {
                  props.history.push({
                    pathname: '/' + routename + '/view', state: {
                      propdata: x
                    }
                  })
                }}>
                  <Tooltip title={"View Details"} placement="bottom">
                    <VisibilityIcon style={{ color: "#fff", cursor: "pointer" }} />
                  </Tooltip>
                </Button>
                {routename !== "edgebox" ? <Button color="danger"
                  disabled={x.username === localStorage.getItem('username') ? true : false}
                  onClick={() => {

                    if (routename === "vehicle") {
                      SetdeleteItem(x[Object.keys(x)[0]])
                      SetAcivityItem(true)
                    } else if (routename === "visitor") {
                      setItem(x[Object.keys(x)[2]])
                      setOpen(true)
                    } else {
                      if (routename === "user") {
                        setOpen(true)
                        setItem(x[Object.keys(x)[1]])
                      } else {
                        setOpen(true)
                        setItem(x[Object.keys(x)[0]])
                      }

                    }
                  }} aria-label="delete" justIcon round style={{ marginLeft: 10 }} >
                  {
                    x.username === localStorage.getItem('username') ?
                      <Tooltip title={"can't delete current user"} placement="left">
                        <PersonAddDisabledIcon style={{ color: "#fff", cursor: "pointer" }} />
                      </Tooltip>
                      :
                      <Tooltip title={"Delete"} placement="left">
                        <DeleteIcon style={{ color: "#fff", cursor: "pointer" }} />
                      </Tooltip>
                  }
                </Button> : null}
              </div>
            </TableCell>
          )
        }
        else if (y.prop === "action" && routename === "vehicle") {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <Button color="danger" onClick={() => {
                if (x.isactivity === false) {
                  setOpen(true)
                  setItem(x[Object.keys(x)[0]])
                } else {
                  SetdeleteItem(x[Object.keys(x)[0]])
                  SetAcivityItem(true)
                }
              }} aria-label="delete" justIcon round style={{ marginLeft: 10 }} >
                <Tooltip title={"Delete"} placement="left">
                  <DeleteIcon style={{ color: "#fff", cursor: "pointer" }} />
                </Tooltip>
              </Button>
            </TableCell>
          )
        }


        else if (y.prop === "comments") {
          return (
            <TableCell className={classes.tableCell} style={{ width: 70 }} key={k}>
              {x.comments ?
                <Button color="info" aria-label="view" justIcon round >
                  <Tooltip title={x.comments} placement="right">
                    <CommentIcon style={{ color: "#fff", cursor: "pointer" }} />
                  </Tooltip>
                </Button>
                : ""}
            </TableCell>
          )
        }
        else if (y.prop === "checkbox" && window.sessionStorage.getItem('tenant') === "true" && window.localStorage.getItem('role') !== "IsTenant") {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <Checkbox
                checked={x.isapproved || vehicleIds.map((h) => h.vehicleid).includes(x.vehicleid) || false}
                // id={x.vehicleid}
                Key={x.vehicleid}
                disabled={x.isapproved}
                onChange={(e) => handleCheck(e, x)}

                inputProps={{ 'aria-label': 'primary checkbox' }}
              />

            </TableCell>
          )
        }
        else if (y.prop === "isapproved" && x[y.prop] && window.sessionStorage.getItem('tenant') === "true" && window.localStorage.getItem('role') !== "IsTenant") {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <Tooltip title={"Approved"} placement="right">
                <FiberManualRecordRoundedIcon style={{ color: "green", marginLeft: 10 }} />
              </Tooltip>
            </TableCell>
          )
        }
        else if (y.prop === "isapproved" && !x[y.prop] && window.sessionStorage.getItem('tenant') === "true" && window.localStorage.getItem('role') !== "IsTenant") {
          return (
            <TableCell className={classes.tableCell} key={k}>
              <Tooltip title={"Not approved"} placement="right">
                <FiberManualRecordRoundedIcon style={{ color: "red", cursor: "pointer", marginLeft: 10 }} onClick={() => {
                  setOpenApproval(true)
                  setTenant(x)
                }} />
              </Tooltip>
            </TableCell>
          )
        }
        else if (y.prop === "s.no") {
          return (
            <TableCell className={classes.tableCell} style={{ width: 70 }} key={k}>
              {indexOfLastPost + (i + 1)}
            </TableCell>
          )
        }


        else {

          return (
            <TableCell className={classes.tableCell} key={k}>
              {x[y.prop]}
            </TableCell>
          )
        }
      }
      )}
    </>
  )
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property)
  };
  const stableSort = (array, comparator) => {
    finalCount.current = array.map((el, index) => [el, index]);
    finalCount.current.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return finalCount.current.map((el) => el[0]);
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const descendingComparator = (a, b, orderBy) => {
    if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
      return -1;
    }
    if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }
  const handleNetworkChange = () => {
    setOnline(window.navigator.onLine)
  }
  const getdatabase = async () => {

    const data = await getDocs(usersCollectionRef);
    //  console.log(data.docs.data())
    setData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

  }
  useEffect(() => {

    window.addEventListener('offline', handleNetworkChange);
    window.addEventListener('online', handleNetworkChange);
   
    getdatabase();


  }, []);


  const StatusforShareActivity = () => {
    SetModalForAsk(false)
    setLoader(true)
    let updateApi = HOST + '/vehicle/activityyes'
    let obj = { vehicleid: ShareActivityData.vehicleid }
    axios.put(updateApi, obj, {
      headers: {
        "authToken": token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(response => {

      showNotification("tc")
      seterroertext(" Share Activity " + Alert.upadateSuccess)
      getVehiclesDetails()
      setLoader(false)
    }).catch((err) => {
      if (err.message === "Network Error") {
        setLoader(false)
        setnetWorkError(true)
        seterroertext(Settings.netWorkError().errmessgae)
        setTimeout(() => { setnetWorkError(false) }, 3000)
      }
      if (err.response) {
        if (err.response.status === 502) {
          setLoader(false)
          setnetWorkError(true)
          seterroertext(Settings.Server().errmessgae)
          setTimeout(() => { setnetWorkError(false) }, 3000)
        }
      }
    })
  }

  const getTenantVehicleIds = (event) => {
    let tempList = TableMaindata;
    // Check/ UnCheck All Items         
    if (event.target.checked === true) {
      tempList.map((data, i) => {
        if (!data.isapproved) {

          if (vehicleIds.map((h) => h.vehicleid).includes(data.vehicleid) !== true) {
            const obj = { 'vehicleid': data.vehicleid };
            vehicleIds.push(obj);

          }
        }
      })
    }
    else {
      setVehicleIds([])
    }

    //Update State
    setMasterChecked(!MasterChecked)
  }


  const handleError = err => {
    console.error(err)
  }
  return (
    <div>
      {tcc ? <SnackbarContent
        icon={InfoIcon}
        message={erroertext}
        // close
        color="danger"
      /> : ""}
      <div className={classes.tableResponsive}>
        {tc ? <Snackbar
          place="tc"
          color="success"
          icon={ThumbUpIcon}
          message={erroertext}
          open={tc}
          closeNotification={() => setTC(false)}
          close
        /> : ""}

        <ConfirmDialog
          title="If you delete a vehicle, this user will stop receiving vehicle activity information"
          open={ActivityItem}
          setOpen={SetAcivityItem}
          onConfirm={() => {
            DeleteDataActivity()
          }}
        >
          {""}
        </ConfirmDialog>
        <ConfirmDialog
          title="Are you sure?"
          open={ModalForAsk}
          setOpen={SetModalForAsk}
          onConfirm={() => {
            StatusforShareActivity()
          }}
        >
          You want to change the share activity status?
        </ConfirmDialog>

        <ConfirmDialog
          title="Are you sure?"
          open={openApproval}
          setOpen={setOpenApproval}
          onConfirm={() => {
            updateTenantApproval(tenant)
          }}
        >
          You want to give access for tenant vehicle?
        </ConfirmDialog>



        <ConfirmDialog
          title="Are you sure?"
          open={open}
          setOpen={setOpen}
          onConfirm={() => DeleteData()}
        >
          You will not be able to recover this information once it is deleted
        </ConfirmDialog>

        <GridContainer >
          <GridItem xs={12} sm={12} md={5} lg={4}>
            <CustomInput formControlProps={{
              className: classes.root
            }}
              inputProps={{
                placeholder: "Search",
                onChange: (e) => {
                  setFilterText(e.target.value)
                }
              }} />
            <Button color="primary" aria-label="edit" justIcon round onClick={() => {
              const filteritems = TableMaindata.filter(x => {
                const values = tableHead.filter((y, k) => {
                  return String(x[y.prop]).toLocaleLowerCase().includes(filterText.toLocaleLowerCase()).trim()
                })
                if (values.length > 0) {
                  return true
                }
                else {
                  return false
                }
              });
              setData(filteritems)
            }}>

              <Tooltip title={"Search"} placement="right">
                <Search />
              </Tooltip>
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={5} lg={4}>
          </GridItem>
          <GridItem xs={12} sm={12} md={2} lg={2}>
            {window.sessionStorage.getItem('tenant') === "true" && window.localStorage.getItem('role') !== "IsTenant" && routename === "vehicle" ?
              vehicleIds.length !== 0 ? <Button color="primary" onClick={() => updateTenantApproval(vehicleIds)} >Vehicles Approval</Button> : <Button>Vehicles Approval</Button> : ""}
          </GridItem>
          <GridItem xs={12} sm={12} md={2} lg={2}>

            <Button color="primary" onClick={() => {
              var html = document.querySelector("table").outerHTML;

              export_table_to_csv(html, "  Records as on " + moment(new Date()).format("DD-MM-YYYY") + ".csv")

            }
            }><Icon icon={microsoftExcel} />Export Excel</Button>
          </GridItem>
        </GridContainer>
        {loader ? <LoaderComponent /> : ""}

        <Table className={classes.table} >

          {tableHead !== undefined ? (
            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
              <TableRow className={classes.tableHeadRow}
                aria-checked={checked}


              >


                {tableHead.map((prop, key) => {

                  return (
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}

                      key={key}
                    >
                      {window.sessionStorage.getItem('tenant') === "true" && window.localStorage.getItem('role') !== "IsTenant" && prop.prop === "checkbox" ?
                        <Checkbox
                          checked={MasterChecked}

                          onChange={(event) => getTenantVehicleIds(event)}></Checkbox>


                        : ""}
                      <TableSortLabel
                        IconComponent={ArrowUpwardIcon}
                        active={orderBy === prop.prop}
                        hideSortIcon={prop.name !== "S.No" && prop.name !== "Vehicle Image" && prop.name !== "Action" && prop.name !== "Share Activity" && prop.name !== "Comments" && prop.name !== "Visitor" && prop.name !== "Vehicle Approval" && prop.name !== "" ? false : true}
                        direction={orderBy === prop.prop ? order : 'asc'}
                        onClick={() => {
                          if (prop.name !== "S.No" && prop.name !== "Vehicle Image" && prop.name !== "Action" && prop.name !== "Share Activity" && prop.name !== "Comments" && prop.name !== "Visitor" && prop.name !== "Vehicle Approval") {
                            handleRequestSort(prop.prop)
                          }
                        }}
                      >
                        {window.sessionStorage.getItem('tenant') === "false" || window.localStorage.getItem('role') === "IsTenant" ? prop.name === "Vehicle Approval" ? prop.name = "" : prop.name : prop.name}

                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}

          <TableBody>
            {netWorkError ? <SnackbarContent
              icon={InfoIcon}
              message={erroertext}
              // close
              color="danger"
            /> : ""}
            {TableMaindata.length !== 0 && stableSort(filterText ? TableMaindata.filter(x => {
              const values = tableHead.filter((y, k) => {
                if (typeof filterText !== "number") {
                  return String(x[y.prop]).replace(/\s+/g, "").toLocaleLowerCase().includes(String(filterText.replace(/\s+/g, "")).toLocaleLowerCase())
                } else {
                  return String(x[y.prop]).replace(/\s+/g, "").includes(String(filterText.replace(/\s+/g, "")))
                }
              })
              if (values.length > 0) {
                return true
              }
              else {
                return false
              }
            }) : TableMaindata, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((prop, key) => {

              return (
                <TableRow key={key} className={classes.tableBodyRow}>

                  {row(prop, key, tableHead)}
                </TableRow>
              );
            })}

          </TableBody>
          <caption style={{
            position: "relative",
            top: "-53px",
            bottom: 0,
            left: "-13px",
            color: "#000"
          }}> Total No.of Records : {filterText ? finalCount.current.length : TableMaindata.length}</caption>
          <TableFooter className={classes.footer}>
            <TableRow className={classes.footer}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={"Records Per Page"}
                count={filterText ? finalCount.current.length : TableMaindata.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div >
  );

}

CustomTable.defaultProps = {
  tableHeaderColor: "gray"
};
// EnhancedTableHead.propTypes = {
//   classes: PropTypes.object.isRequired,
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// };
CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};
export default withRouter(CustomTable)
