import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import React, { Component } from 'react';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Button from "components/CustomButtons/Button.js";
import moment from 'moment'
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addcompany: false,
      currentdate :new Date()
    }
  }
  render() {

    const { classes } = this.props;
    return (
      <div>
             <GridContainer>
          <GridItem xs={12} sm={6} md={4}>

          </GridItem>
          <GridItem xs={12} sm={6} md={4}>

          </GridItem>
          <GridItem xs={12} sm={6} md={2}>
          </GridItem>
          <GridItem xs={12} sm={6} md={2}>
            <div style={{ width: "100%", display: "flex", alignItems: 'center', justifyContent: "center" }}>
            <Button color="primary"  onClick={() => {
                this.props.history.push('/help')
              }} >Help</Button>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{"Customers Records"}</h4>
                <p className={classes.cardCategoryWhite}>
                 Customer details as on  {moment(this.state.currentdate).format("DD-MM-YYYY")}
              </p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="success"
                  tableHead={[
                    {
                      name: "S.No",
                      prop: "s.no"
                    },
                    {
                        name: "Person Name",
                        prop: "fullname"
                      },
                    {
                      name: "Mobile Number",
                      prop: "mobile"
                    },
                    {
                        name: "Email",
                        prop: "email"
                      },
                    {
                        name: "Comments",
                        prop: "details"
                      },
                    {
                      name: "Created On",
                      prop: "createdon"
                    },
                    // {
                    //   name: "Action",
                    //   prop: "action"
                    // }
                ]}
                  tableData={this.state.vehicles}
                  routename="support"
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withRouter((withStyles(styles)(Dashboard)));
