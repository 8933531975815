// import React from 'react';
// import logo from './img/logo.png';
// import axios from 'axios';
// import { AUTH, HOST } from './config';
// import Loader from './img/icon.png';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
// import InputLabel from '@material-ui/core/InputLabel';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import FormControl from '@material-ui/core/FormControl';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import SnackbarContent from "components/Snackbar/SnackbarContent.js";
// import InfoIcon from '@material-ui/icons/Info';
// import { withStyles } from "@material-ui/core/styles";
// import GridItem from "components/Grid/GridItem.js";
// import GridContainer from "components/Grid/GridContainer.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";
// import Checkbox from "@material-ui/core/Checkbox";
// import { green } from '@material-ui/core/colors';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// // import TextField from '@material-ui/core/TextField';
// import LoaderComponent from './GlobalConstant/LoaderComponent'
// import Settings from './GlobalConstant/Settings';
// // import {_goto} from './GlobalConstant/OfflineNotice'
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// import LockIcon from '@material-ui/icons/Lock';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import LockOpenIcon from '@material-ui/icons/LockOpen';
// import CookieSetService from './GlobalConstant/CookieSetService'
// class ChangePassword extends React.Component {
//   constructor(props, data) {
//     super(props);
//     this.state = {
//       newpassword: '',
//       confirmpassword: '',
//       vehicles: [],
//       flagError: false,
//       flagError1: false,
//       showPassword: false,
//       showPassword1: false,
//       requirederror: false,
//       minmaxerror: false
//     }
//     this.handleSubmit = this.handleSubmit.bind(this);
//   }

//   componentDidMount() {
//     this.location = window.location.href.replace('http://localhost:3000/reset?','');
//     console.log(this.location,"this resetcode");
//     // this.location = window.location.href.replace('http://gate.gaido.in/reset?', '');
//   }

//   handleSubmit(e) {
//     this.setState({ loader: true });
//     this.setState({ flagError: false });
//     const obj = {
//       resetcode: this.location,
//       pwd: this.state.newpassword
//     };
//     const { newpassword, confirmpassword } = this.state;
//     if (newpassword !== confirmpassword) {
//       this.setState({ flagError: true });
//       setTimeout(() => { this.setState({ flagError: false }) }, 3000);
//     } else {
//       if (this.state.newpassword.replace(/\s+/g, '').length >= 3 && this.state.newpassword.replace(/\s+/g, '').length <= 20 || this.state.confirmpassword.replace(/\s+/g, '').length >= 3 && this.state.confirmpassword.replace(/\s+/g, '').length <= 20) {
//         axios.put(AUTH + '/resetpassword', obj, {
//           headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//           }
//         })
//           .then(res => {
//             if (res.data.statusid === 201) {
//               this.setState({ loader: false })
//               this.setState({ flagError1: true });
//               setTimeout(() => {
//                 this.setState({ flagError1: false })
//                 this.props.history.push("/")
//               }, 5000);
//             } else {
//               this.setState({ loader: false })
//               alert('Something went wrong please try again later!')
//               setTimeout(() => { this.props.history.push("/") }, 2000);
//             }
//           })
//           .catch(function (error) {
//             this.setState({ loader: false })
//             alert('Something went wrong please try again later!')
//           })
//       } else {
//         this.setState({ minmaxerror: true, loader: false })
//         setTimeout(() => { this.setState({ minmaxerror: false }) }, 2000);
//       }
//     }
//   }
//   render() {
//     const handleClickShowPassword = () => {
//       this.setState({ ...this.state.newpassword, showPassword: !this.state.showPassword });
//     };
//     const handleClickShowPasswordconfirm = () => {
//       this.setState({ ...this.state.confirmpassword, showPassword1: !this.state.showPassword1 });
//     };

//     const handleMouseDownPassword = (event) => {
//       event.preventDefault()
//     };
//     return (
//       <div className="App">
//         <div className="auth" style={{ backgroundImage: 'url(' + require('./img/login-bg.jpg') + ')', backgroundSize: 'cover' }}>
//           <div className="auth-container">
//             {this.state.loader ?
//               <div id="loader">
//                 <div className="loader-back"></div>
//                 <img src={Loader} alt="Gaido Technologies" />
//               </div> : ''}
//             <div className="card">
//               <header className="auth-header">
//                 <h1 className="auth-title">
//                   <img src={logo} alt="Gaido Technologies" />
//                 </h1>
//               </header>
//               <div className="auth-content">
//                 <div className="text-center" style={{ marginBottom: "1rem" }}>
//                   {this.state.flagError ?
//                   <SnackbarContent
//                   icon={InfoIcon}
//                   message="New and Confirm Password not Match"
//                   // close
//                   color="danger"
//               />
//                     : ''}
//                   {this.state.flagError1 ?
//                    <SnackbarContent
//                    icon={InfoIcon}
//                    message="Password successfully upadted"
//                    // close
//                    color="success"
//                />
//                      : ''}
//                   {this.state.requirederror ?
//                    <SnackbarContent
//                    icon={InfoIcon}
//                    message="Please fill in all required fields"
//                    // close
//                    color="danger"
//                />
//                      : ''
//                    }
//                   {this.state.minmaxerror ?<SnackbarContent
//                    icon={InfoIcon}
//                    message="Password length min 3 and max 20"
//                    // close
//                    color="danger"
//                />
//                      : ''
//                     }
//                 </div>
//                 <h2 className="login-heading">Change Password </h2>
//                 <p className="text-center">Update New Password Here!</p>
//                 <div className="row">
//                   <div className="col-md-1">
//                   </div>
//                   <div className="col-md-10" style={{ display: "flex", alignItems: 'center', justifyContent: 'center', marginBottom: "0.5rem", marginTop: "0.5rem" }}>
//                     <div className="form-group" autoComplete="password">
//                       <FormControl variant="outlined" >
//                         <InputLabel htmlFor="outlined-adornment-password" error={this.state.flagError} required>New password</InputLabel>
//                         <OutlinedInput
//                           autoComplete="on"
//                           label="New password *"
//                           placeholder="xxxxxxxxxxxxx"
//                           error={this.state.flagError}
//                           id="outlined-adornment-password"
//                           type={this.state.showPassword ? 'text' : 'password'}
//                           value={this.state.newpassword}
//                           onChange={(e) => this.setState({ newpassword: e.target.value })}
//                           endAdornment={
//                             <InputAdornment position="end">
//                               <div onClick={handleClickShowPassword}
//                                 onMouseDown={handleMouseDownPassword} style={{ cursor: "pointer" }}>
//                                 {this.state.showPassword ? <Visibility style={{ color: "rgba(0, 0, 0, 0.54)" }} /> : <VisibilityOff style={{ color: "rgba(0, 0, 0, 0.54)" }} />}
//                               </div>
//                             </InputAdornment>
//                           }
//                           labelWidth={10}
//                         />
//                       </FormControl>
//                     </div>
//                   </div>
//                   <div className="col-md-1">
//                   </div>
//                 </div>
//                 <div className="row">
//                   <div className="col-md-1">
//                   </div>
//                   <div className="col-md-10" style={{ display: "flex", alignItems: 'center', justifyContent: 'center', marginBottom: "0.5rem", marginTop: "0.5rem" }}>
//                     <div className="form-group" autoComplete="password">
//                       <FormControl variant="outlined" >
//                         <InputLabel htmlFor="outlined-adornment-password" error={this.state.flagError} required>Confirm password</InputLabel>
//                         <OutlinedInput
//                           autoComplete="on"
//                           label="Confirm password *"
//                           placeholder="xxxxxxxxxxxxx"
//                           error={this.state.flagError}
//                           id="outlined-adornment-password"
//                           type={this.state.showPassword1 ? 'text' : 'password'}
//                           value={this.state.confirmpassword}
//                           onChange={(e) => this.setState({ confirmpassword: e.target.value })}
//                           onKeyPress={
//                             (event) => {
//                               if (event.key == 'Enter') {
//                                 this.handleSubmit()
//                               }
//                             }
//                           }
//                           endAdornment={
//                             <InputAdornment position="end">
//                               <div onClick={handleClickShowPasswordconfirm}
//                                 onMouseDown={handleMouseDownPassword} style={{ cursor: "pointer" }}>
//                                 {this.state.showPassword1 ? <Visibility style={{ color: "rgba(0, 0, 0, 0.54)" }} /> : <VisibilityOff style={{ color: "rgba(0, 0, 0, 0.54)" }} />}
//                               </div>
//                             </InputAdornment>
//                           }
//                           labelWidth={10}
//                         />
//                       </FormControl>
//                     </div>
//                   </div>
//                   <div className="col-md-1">
//                   </div>
//                 </div>
//                 <div className="row">
//                   <div className="col-md-3">
//                   </div>
//                   <div className="col-md-6" style={{ display: "flex", alignItems: 'center', justifyContent: 'center', marginBottom: "0.5rem", marginTop: "0.5rem" }}>
//                     <div className="form-group">
//                       {this.state.newpassword != "" && this.state.confirmpassword != "" ? <button type="submit" className="signin" onClick={this.handleSubmit}>Update</button> : <button onClick={() => {
//                         this.setState({ requirederror: true })
//                         setTimeout(() => { this.setState({ requirederror: false }) }, 2000);
//                       }} className="signinerorr">Update</button>}
//                     </div>
//                   </div>
//                   <div className="col-md-3">
//                   </div>
//                 </div>
//                 {/* <Link to="/" class="forgot-btn pull-right">Sign In</Link> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default ChangePassword;


import React, { Component } from 'react';
// @material-ui/core components
import axios from 'axios'
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Checkbox from "@material-ui/core/Checkbox";
import { green } from '@material-ui/core/colors';
import { AUTH } from './config';
import LoaderComponent from './GlobalConstant/LoaderComponent'
import Settings from './GlobalConstant/Settings';
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InputAdornment from '@material-ui/core/InputAdornment';
import InfoIcon from '@material-ui/icons/Info';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LockOpenIcon from '@material-ui/icons/LockOpen';
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  dialog: {
    // backgroundImage: "url(http://lpr.gaido.in/static/media/bulkcar.8585b4d8.jpg)",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    width: "100%",
    overflowX: "hidden",
    overflowy: "auto",
  },
  root: {
    width: "100%",
    minHeight: 330,
    position: "relative",
    top: "50%",
    left: "50%",
    // webkitTransform:" translateY(-50%) translateX(-50%)",
    transform: "translateY(-50%) translateX(-50%)",
  }
};
class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: "",
      Password: "",
      netWorkError: false,
      flagError2: false,
      flagError3: false,
      loader: false,
      erroertext: "",
      roletype: "",
      i_agree: false,
      viewpassword: false,
      inputname: "",
      online: window.navigator.onLine,
      checkedG: false,
      newpassword: '',
      confirmpassword: '',
      vehicles: [],
      flagError: false,
      flagError1: false,
      showPassword: false,
      showPassword1: false,
      requirederror: false,
      minmaxerror: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.addEventListener('offline', this.handleNetworkChange);
    window.addEventListener('online', this.handleNetworkChange);
    // this.location = window.location.href.replace('http://localhost:3000/reset?', '');
    this.location = window.location.href.replace('https://gate.gaido.in/reset?', '');
  }
  handleSubmit(e) {
    this.setState({ flagError: false });
    const obj = {
      resetcode: this.location,
      pwd: this.state.newpassword
    };
    const { newpassword, confirmpassword } = this.state;
    if (newpassword !== confirmpassword) {
      this.setState({ flagError3: true, erroertext: "New password and confirm passowrd didn't match" });
      setTimeout(() => { this.setState({ flagError3: false }) }, 3000);
    } else {
      if (Settings.NewPasswordMain(this.state.newpassword).status) {
        if (Settings.ConfirmPasswordMain(this.state.confirmpassword).status) {
          this.setState({ loader: true });
          axios.put(AUTH + '/resetpassword', obj, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }).then(res => {
              if (res.data.statusid === 202) {
                this.setState({ loader: false })
                this.setState({ flagError2: true, erroertext: "Something went wrong please try again later" });
                setTimeout(() => {
                  this.setState({ flagError2: false })
                  this.props.history.push("/")
                }, 2000);
              } else {
                this.setState({ loader: false })
                this.setState({ flagError1: true, erroertext: "Password Successfully updated" });
                setTimeout(() => {
                  this.setState({ flagError1: false })
                  this.props.history.push("/")
                }, 5000);
              }
            })
            .catch(err => {
              if (err.message === "Network Error") {
                this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
              }
              if (err.response) {
                if (err.response.status === 401) {
                  this.setState({ netWorkError: true, loader: false, erroertext: Settings.Unauthrized().errmessgae })
                  setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
                if (err.response.status === 502) {
                  this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                  setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
              }
            })
        } else {
          this.setState({ flagError: true, erroertext: Settings.ConfirmPasswordMain(this.state.newpassword).errmessgae, inputname: Settings.ConfirmPasswordMain(this.state.newpassword).inputname })
        }

      } else {
        this.setState({ flagError: true, erroertext: Settings.ConfirmPasswordMain(this.state.newpassword).errmessgae, inputname: Settings.ConfirmPasswordMain(this.state.newpassword).inputname })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('offline', this.handleNetworkChange);
    window.removeEventListener('online', this.handleNetworkChange);
  }
  handleNetworkChange = () => {
    this.setState({ online: window.navigator.onLine });
  }


  render() {

    const { classes } = this.props
    return (
      <div className={classes.dialog}>
        <div className={classes.root}>
          <GridContainer  >
            <GridItem xs={12} sm={12} md={4}>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} className={classes.root}>
              <Card >
                <CardHeader color="primary" >
                  <h4 className={classes.cardTitleWhite} >{Settings.titleCase("Reset Password")}</h4>
                  <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Please fill in all required feilds")}</p>
                </CardHeader>
                <CardBody>
                  <GridContainer  >
                    <GridItem xs={12} sm={12} md={12} >
                      {this.state.netWorkError ? <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      /> : ""}
                      {this.state.flagError2 ? <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      /> : ""}
                      {this.state.flagError3 ? <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      /> : ""}
                      {this.state.flagError1 ? <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="success"
                      /> : ""}
                      <CustomInput
                        labelText="* New Password(min. 3 & max. 20)"
                        id="Mobile"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (event) => {
                            this.setState({ newpassword: event.target.value })
                            if (Settings.NewPasswordMain(event.target.value).status) {
                              this.setState({ flagError: false, erroertext: "" })
                            } else {
                              this.setState({ flagError: true, erroertext: Settings.NewPasswordMain(event.target.value).errmessgae, inputname: Settings.NewPasswordMain(event.target.value).inputname })
                            }
                          },
                          type: this.state.showPassword ? "text" : "password",
                          value: this.state.newpassword,
                          error: this.state.newpassword === "" ? this.state.requirederror : "",
                          endAdornment: (
                            <InputAdornment position="end">
                              <div style={{ cursor: "pointer" }} onClick={() => {
                                this.setState({ showPassword: !this.state.showPassword })
                              }}>
                                {this.state.showPassword ? <VisibilityIcon className={classes.inputIconsColor} /> : <VisibilityOffIcon className={classes.inputIconsColor} />}
                              </div>
                            </InputAdornment>
                          )
                        }}
                      />
                      {this.state.requirederror && !this.state.newpassword ? <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      /> : ""}
                      {this.state.flagError && this.state.inputname === "NewPassword" ? <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      /> : ""}
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="* Confirm Password(min. 3 & max. 20)"
                        id="Mobile"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (event) => {
                            this.setState({ confirmpassword: event.target.value })
                            if (Settings.ConfirmPasswordMain(event.target.value).status) {
                              this.setState({ flagError: false, erroertext: "" })
                            } else {
                              this.setState({ flagError: true, erroertext: Settings.ConfirmPasswordMain(event.target.value).errmessgae, inputname: Settings.ConfirmPasswordMain(event.target.value).inputname })
                            }
                          },
                          type: this.state.showPassword1 ? "text" : "password",
                          value: this.state.confirmpassword,
                          error: this.state.confirmpassword === "" ? this.state.requirederror : "",
                          endAdornment: (
                            <InputAdornment position="end">
                              <div style={{ cursor: "pointer" }} onClick={() => {
                                this.setState({ showPassword1: !this.state.showPassword1 })
                              }}>
                                {this.state.showPassword1 ? <VisibilityIcon className={classes.inputIconsColor} /> : <VisibilityOffIcon className={classes.inputIconsColor} />}
                              </div>
                            </InputAdornment>
                          )
                        }}
                      />
                      {this.state.requirederror && !this.state.confirmpassword ? <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      /> : ""}
                      {this.state.flagError && this.state.inputname === "ConfirmPassword" ? <SnackbarContent
                        icon={InfoIcon}
                        message={this.state.erroertext}
                        // close
                        color="danger"
                      /> : ""}
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <div style={{ cursor: "pointer" }}>
                    {this.state.newpassword !== "" && this.state.confirmpassword !== "" ? <Button color={"primary"} onClick={this.handleSubmit}>Reset</Button> : <Button
                      onClick={() => {
                        if (Settings.requireerrors([this.state.newpassword, this.state.confirmpassword]).status === true) {
                          this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.newpassword, this.state.confirmpassword]).errmessgae })
                          setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                        }
                      }}
                    >Reset</Button>}
                  </div>
                  <p color="primary" style={{ marginRight: 5, cursor: "pointer", color: "#1877f2" }} onClick={() => {
                    this.props.history.push('/')
                  }}>{Settings.titleCase("Sign In")}</p>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            </GridItem>
          </GridContainer>
          {this.state.loader ? <LoaderComponent /> : ""}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(login);
