
import React, { Component } from 'react'
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { withRouter } from "react-router-dom";
import Settings from '../../lprfolders/GlobalConstant/Settings';
import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InfoIcon from '@material-ui/icons/Info';
import axios from 'axios';
import Table from "Grids/Reports";
import { HOST } from '../../lprfolders/config';
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
const { GoogleSpreadsheet } = require('google-spreadsheet');
const doc = new GoogleSpreadsheet('1URAtMYd838UrUDGMnqD81JgBbVWuziBWoOJeBuSOqfQ');
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};
class Searchplates extends Component {
    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
        this.state = {
            open: false,
            selectedFromDate: new Date(),
            selectedToDate: new Date(),
            vehicle: "",
            vehicles: [],
            flagError: false,
            netWorkError: false,
            inputname: "",
            erroertext: "",
            showtable: false,
            mobile: "",
            emptystate: false
        }
    }

    handleFromDateChange = (date) => {
        let startDate = date
        let todate = this.state.selectedToDate
        if (startDate.getTime() <= todate.getTime()) {
            this.setState({ erroertext: "", flagError: false, selectedFromDate: date })
        } else {
            this.setState({ erroertext: "To date should be after the start date ", flagError: true, inputname: "Date", selectedFromDate: date })
            setTimeout(() => { this.setState({ flagError: false }) }, 10000);
        }
    }
    handleToDateChange = (date) => {
        let startDate = this.state.selectedFromDate
        let todate = date
        if (startDate.getTime() <= todate.getTime()) {
            this.setState({ erroertext: "", flagError: false, selectedToDate: date })
        } else {
            this.setState({ erroertext: "To date should be after the start date ", flagError: true, inputname: "Date", selectedToDate: date })
            setTimeout(() => { this.setState({ flagError: false }) }, 10000);

        }
    };
    trackVehicle = async () => {
        this.setState({ showtable: false })

        let startDate = this.state.selectedFromDate
        let todate = this.state.selectedToDate
        let count = 0
        if (this.state.vehicle === "") {
            count = 0
        }
        if (this.state.vehicle) {
            if (Settings.TrackVehicle(this.state.vehicle).status) {
                count = 0
            }
            else {
                count++
                this.setState({ flagError: true, erroertext: Settings.TrackVehicle(this.state.vehicle).errmessgae, inputname: Settings.TrackVehicle(this.state.vehicle).inputname })
                setTimeout(() => { this.setState({ flagError: false }) }, 3000);
            }
        }
        if (startDate.getTime() <= todate.getTime()) {

        } else {
            count++
            this.setState({ erroertext: "To date should be after the start date ", flagError: true, inputname: "Date" })
            setTimeout(() => { this.setState({ flagError: false }) }, 3000)
        }
        if (count === 0) {
            this.setState({ loader: true })
            const raw_data = [];
            await doc.useServiceAccountAuth({
                // env var values are copied from service account credentials generated by google
                // see "Authentication" section in docs for more info
                client_email: "lprdata-reactsheet-13@lprdata-sheet.iam.gserviceaccount.com",
                private_key: ("-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDNtJXk5GCuJPKC\nbmzhW80j1sW4qdAWh6xekv5cvMOmt74WPOUF7YpPVdJXc4+c5h5cKybIpJAYJ+fY\nNNm33bndmepvsit5ZLF+2XHI6RyPmAlJZgOAnd61AXjc5bbhQP+nEDJOTMw+mF/l\nRRb4gBNMZt/jn2dCvZKQsTzvN4+L/ayzXm3BgKt3p16Hw2WcQRrOqtarpZDFhpVJ\nnxSSI6IROJWtTHgejhOKYgJ9goHNrzYqWCKn1pJrZe3lqLmPMtiE4kDDZ3lifYrz\nxfUy2z7uFZTHFXPm7JlWpnFP1ST3vUS1DZe8xUg6amVuPSrINBmN3gTkxVtKGuDn\niGHroEyrAgMBAAECggEAZoHnsm0WOqHB0bqaeoEHTKVB2YCR6DBK9sQUin0smH92\n89j7ngUeB+PyEk9NkTfhiDeet5y+C7OTJtxLZINTFHmXaHrmjDNf+iD8wUxdRbdT\nZWa3Nxg0u4mUw6ubg0ujOorpLri5jTXcj8FqrYIRW+DmECXGzOzwRTd1cJYE7BKj\nxi/6IOgrcb74Z2FLmHrTB5XRC02rfZQ+64WSHO5QnArG61OswIxGVBH7OuRO/BgN\nOF2AgbP0sFVxRlHjfpvqbUed8kKxjLKMrumNlS/nGpFvnox+SOIOsZQStm5/T1v/\nZjb8XiyyPZjbbeX+xin/Vv6q0eOQ2Vfd0YZipaV5pQKBgQD5Try1QTnM88qZxKHg\ngZa75wH2KU3xVkxPrPExGyIpTZ/7SJZRynWT7Mt6dVTsGXLD8fJbQSVg2Bd3K6oR\ns1jvFPngaY4ukdTy3RJhzVacPoB7T6Fs6i+IOWY3x1iAyXr/orRxSaDaG1FDG7ep\nA/lV7i+b3d1FGlZVNDavEIVupwKBgQDTOjXtp/s9HPToHKu4YPqulwIwp6ofbDg5\n3RgC+vaATFPMnHan2nRid/a5uPC44ob5WSlc+TyiE/hExbxfhvp8Oxgbu2R1Pyzw\ni3L6JO9qc4imcRVaVEhGDEModd9MGDDGlrie9OlQuFwMg1pukn2r2lyYaFOnu3Wd\n2xFLao1WXQKBgFSHwuo+JGf+nUpH5o8lwUMSz5J0bM4Qca1uORVqWsngXQcplHZF\nDFrMpi1uTQ4EjuorRf5090R/4GzyrCZ2RghCd3sEr0XRAoAaPxpUMjKl6pBk7pns\nSZOJysPM3pBEQGUe+MmUqmppasMzSbGP/GGLci8KKfU9OdqH6qavFl7vAoGAPYfg\ne8mKS2QrB46Ki1xcyg0dSzUcPqkUu+5m9z9KHVeetaUCf6UIcELH8ZQpM4JxlSNf\nBEFqY0Bz+vyrAhzczOGeBT4SZoEvwyasKKgnwhfDv9CDStvwbc9WpOupT45gbwkq\n4MsxSB1cWxzMdBdiWnRyssb5T4wxF/nf0NtKLtUCgYEA+MkfKWyNukhKU3ZanxqN\ndOasptjGxANyq1mYPbiSiUMUGQHKWj8kD4ZA6jd9wpZEl9PJ5FoSXckl5N6I2Mu/\nCUEma1hjvunECxEg1HkoIrevIQnLIiGuSkeqO5K18h/AeNAmL+EIX/vyVSJUktKE\nFm4qVYt5/QttEPbIzO+taFc=\n-----END PRIVATE KEY-----\n").replace(/\\n/gm, '\n'),
            });
            await doc.loadInfo(); // loads document properties and worksheets
            const sheet = doc.sheetsByIndex[1]; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]
            const rows = await sheet.getRows();

            for (let i = 0; i < rows.length; i++) {
                let row = rows[i]._rawData;
                if (row[1] === this.state.vehicle && (row[4]) >= moment(new Date(this.state.selectedFromDate)).format("YYYY-MM-DD") && (row[4]) <= moment(new Date(this.state.selectedToDate)).format("YYYY-MM-DD")) {
                    var obj = {
                        db_match: row[0],
                        reading: row[1],
                        eventdate: row[4],
                        eventtime: row[3],
                        direction: row[2],
                        fullimagepath: row[5],

                    }
                    raw_data.push(obj);

                }



            }
            if (raw_data.length === 0) {
                this.setState({ emptystate: true, loader: false, })
                this.setState({ showtable: false })
                setTimeout(() => { this.setState({ emptystate: false }) }, 3000)

            } else {
                this.setState({ vehicles: raw_data })
                this.setState({ showtable: true })
                this.setState({ loader: false })

            }


        }
    }
    render() {

        const { classes } = this.props
        return (
            <div>
                <div >
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={5}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Vehicle Number")}</h4>
                                    <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Enter search critria below")}</p>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="* Vehicle Number(max. 10 characters)"
                                                id="Vehicle Number"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        this.setState({ vehicle: event.target.value })
                                                        if (event.target.value.length >= 1) {
                                                            if (Settings.TrackVehicle(event.target.value).status || !event.target.value) {
                                                                this.setState({ flagError: false, erroertext: "" })
                                                            }
                                                            else {
                                                                this.setState({ flagError: true, erroertext: Settings.TrackVehicle(event.target.value).errmessgae, inputname: Settings.TrackVehicle(event.target.value).inputname })
                                                            }
                                                        } else {
                                                            this.setState({ flagError: false, erroertext: "" })
                                                        }
                                                    },
                                                    onInput: (e) => e.target.value = ("" + e.target.value).toUpperCase().toString().slice(0, 10),
                                                    type: "text",
                                                    // error: this.state.flagError && this.state.inputname === "Vehicle" ? true : false
                                                    error: (this.state.flagError && this.state.inputname === "Vehicle") || (this.state.requirederror && this.state.vehicle === "") ? true : false
                                                }}
                                            />
                                            {this.state.requirederror && !this.state.vehicle ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                            {this.state.flagError && this.state.inputname === "Vehicle" ? <SnackbarContent
                                                icon={InfoIcon}
                                                message={this.state.erroertext}
                                                // close
                                                color="danger"
                                            /> : ""}
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={5}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Select period")}</h4>
                                    <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Results between the selected dates will be displayed")}</p>
                                </CardHeader>
                                <CardBody>

                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <Grid container justify="space-around">
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        color="primary"
                                                        id="date-picker-dialog"
                                                        label="Select from Date"
                                                        format="dd-MM-yyyy"
                                                        value={this.state.selectedFromDate}
                                                        onChange={this.handleFromDateChange}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        maxDate={new Date()}
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <Grid container justify="space-around">
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        color="primary"
                                                        id="date-picker-dialog"
                                                        label="Select to Date"
                                                        format="dd-MM-yyyy"
                                                        value={this.state.selectedToDate}
                                                        maxDate={new Date()}
                                                        onChange={this.handleToDateChange}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </GridItem>
                                    </GridContainer>
                                    {this.state.flagError && this.state.inputname === "Date" ? <SnackbarContent
                                        icon={InfoIcon}
                                        message={this.state.erroertext}
                                        // close
                                        color="danger"
                                    /> : ""}
                                </CardBody>
                                <CardFooter>
                                </CardFooter>
                            </Card>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={2}>
                            {this.state.vehicle !== "" ?
                                <Button color="primary" style={{ merginTop: 30, marginBottom: 30 }} onClick={this.trackVehicle}> <FindReplaceIcon />Track</Button> :
                                <Button style={{ marginBottom: 30 }} onClick={() => {
                                    if (Settings.requireerrors([this.state.vehicle]).status === true) {
                                        this.setState({ requirederror: true, erroertext: Settings.requireerrors([this.state.vehicle]).errmessgae })
                                        setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                                    }
                                }}> <FindReplaceIcon />Track</Button>
                            }
                        </GridItem>
                    </GridContainer>

                </div>
                {this.state.emptystate ? <SnackbarContent
                    icon={InfoIcon}
                    message={"No records found"}
                    // close
                    color="danger"
                /> : ""}
                {this.state.loader ? <LoaderComponent /> : ""}

                {this.state.showtable ? <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Vehicle Activity")}</h4>
                                <p className={classes.cardCategoryWhite}>
                                    {Settings.capitalizeFLetter("Vehicle movement from")} {moment(this.state.selectedFromDate).format("DD-MM-YYYY")} to {moment(this.state.selectedToDate).format("DD-MM-YYYY")}
                                </p>
                            </CardHeader>
                            <CardBody>
                                {this.state.netWorkError ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={this.state.erroertext}
                                    // close
                                    color="danger"
                                /> : ""}
                                {this.state.loader ? <LoaderComponent /> : ""}
                                <Table
                                    tableHeaderColor="success"
                                    tableHead={[
                                        {
                                            name: "Match",
                                            prop: "db_match"
                                        },
                                        {
                                            name: "Vehicle Number",
                                            prop: "reading"
                                        },
                                        {
                                            name: "Date",
                                            prop: "eventdate"
                                        },
                                        {
                                            name: "Time",
                                            prop: "eventtime"
                                        },
                                        {
                                            name: "Direction",
                                            prop: "direction"
                                        },
                                        {
                                            name: "Vehicle Image",
                                            prop: "fullimagepath"
                                        }
                                    ]}
                                    tableData={this.state.vehicles}
                                    routename="lpr"
                                    trackVehicle={this.trackVehicle}
                                    vehicle={this.state.vehicle}
                                    mobile={this.state.mobile}
                                    datefro={moment(this.state.selectedFromDate).format("DD-MM-YYYY")}
                                    dateto={moment(this.state.selectedToDate).format("DD-MM-YYYY")}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                    </GridItem>
                </GridContainer>
                    : ""}
            </div>
        );
    }
}

export default withRouter((withStyles(styles)(Searchplates)));