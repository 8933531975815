
import React, { Component } from 'react'
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { withRouter } from "react-router-dom";
import Settings from '../../lprfolders/GlobalConstant/Settings';
import LoaderComponent from '../../lprfolders/GlobalConstant/LoaderComponent'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import InfoIcon from '@material-ui/icons/Info';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Alert from '../../lprfolders/GlobalConstant/Alert'
import axios from 'axios';
import { HOST } from '../../lprfolders/config';
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};
class Edit extends Component {
    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
        this.state = {
            flagError: false,
            netWorkError: false,
            visitorid: "",
            erroertext: "",
            flatno: "",
            fullname: "",
            mobile: "",
            vehicle: "",
            visitorname: "",
            companyname: "",
            hostname: "",
            hostnameerror: false,
            tomeet: "",
            comments: "",
            inputname: "",
            tc: false,
            bc: false,
            statusid: 0,
            online: window.navigator.onLine

        }
    }
    componentDidMount() {
        this.getvisitordetails()
        window.addEventListener('offline', this.handleNetworkChange);
        window.addEventListener('online', this.handleNetworkChange);
      }
      
      componentWillUnmount() {
        window.removeEventListener('offline', this.handleNetworkChange);
        window.removeEventListener('online', this.handleNetworkChange);
      }
      handleNetworkChange = () => {
        this.setState({ online: window.navigator.onLine });
      }
    add = () => {
        let count = 1

        if (this.state.vehicle === "") {
            count--
        }
        if (this.state.vehicle) {
            if (Settings.Vehicle(this.state.vehicle).status) {
              count--
            }
            else {
                count++
                this.setState({ flagError: true, erroertext: Settings.Vehicle(this.state.vehicle).errmessgae, inputname: Settings.Vehicle(this.state.vehicle).inputname })
                setTimeout(() => { this.setState({ flagError: false }) }, 3000);
            }
        }
        if (Settings.PersonName(this.state.visitorname).status) {
            if (Settings.mobilenumber(this.state.mobile).status) {
                    if (Settings.Comments(this.state.tomeet).status) {



                    } else {
                        count++
                        this.setState({ flagError: true, erroertext: Settings.Comments(this.state.tomeet).errmessgae, inputname: Settings.Comments(this.state.tomeet).inputname })
                        setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                    }

                } 

             else {
                count++
                this.setState({ flagError: true, erroertext: Settings.mobilenumber(this.state.mobile).errmessgae, inputname: Settings.mobilenumber(this.state.mobile).inputname })
                setTimeout(() => { this.setState({ flagError: false }) }, 3000);
            }
        }
        else {
            count++
            this.setState({ hostnameerror: false, flagError: true, erroertext: Settings.PersonName(this.state.visitorname).errmessgae, inputname: Settings.PersonName(this.state.visitorname).inputname })
            setTimeout(() => { this.setState({ flagError: false }) }, 3000);
        }
        if (count === 0) {
         if(this.state.online){

            let api = HOST + "/visitor/update"
            let body = {
                visitorid: this.state.visitorid,
                visitor: this.state.visitorname,
                mobile: this.state.mobile,
                vehicle: this.state.vehicle,
                tomeet: this.state.tomeet,
                statusid: this.state.statusid
            }
            this.setState({ loader: true })
            
            axios.put(api, body, {
                headers: {
                    "authToken": this.token,
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json'
                }
            }).then(res => {
                if (res.status === 201) {
                    this.setState({ loader: false })
                    this.showNotification("tc")
                    setTimeout(() => { this.props.history.push('/visitor') }, 2000);
                }
            }).catch(err => {
                if (err.message === "Network Error") {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
                if (err.response) {
                    if (err.response.data.statusid === 409) {
                        this.setState({ flagError: true, loader: false, erroertext: Settings.CompnayName(409).errmessgae, inputname: Settings.CompnayName(409).inputname })
                        setTimeout(() => { this.setState({ flagError: false }) }, 3000);
                    }
                    if (err.response.status === 502) {
                        this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                    }
                }
            })
        }
        else{
            this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
            setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
        }
    }

    }
    showNotification = place => {
        switch (place) {
            case "tc":
                if (!this.state.tc) {
                    this.setState({ tc: true, erroertext: Alert.upadateSuccess });
                    setTimeout(() => { this.setState({ tc: false }) }, 4000)
                }
                break;
            case "bc":
                if (!this.state.bc) {
                    this.setState({ bc: true });
                    setTimeout(() => { this.setState({ bc: false }) }, 4000)
                }
                break;
            default:
                break;
        }
    };

    getvisitordetails = () => {
        this.setState({ loader: true })
        let api = HOST + "/visitor/get/" + this.props.location.state.propdata.visitorid
        axios.get(api, {
            headers: {
                "authToken": this.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                this.setState({
                    mobile: response.data.mobile,
                    vehicle: response.data.vehicle,
                    visitorname: response.data.visitor,
                    tomeet: response.data.tomeet,
                    visitorid: response.data.visitorid,
                    statusid: response.data.statusid
                });
                this.setState({ loader: false })
            }).catch(err => {
                if (err.message === "Network Error") {
                    this.setState({ netWorkError: true, loader: false, erroertext: Settings.netWorkError().errmessgae })
                    setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                }
                if (err.response) {
                    if (err.response.status === 502) {
                        this.setState({ netWorkError: true, loader: false, erroertext: Settings.Server().errmessgae })
                        setTimeout(() => { this.setState({ netWorkError: false }) }, 3000)
                    }
                }
            })
    }
    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <GridContainer>
                    {this.state.tc ? <Snackbar
                        place="tc"
                        color="success"
                        icon={ThumbUpIcon}
                        message={" Visitor " + this.state.erroertext}
                        open={this.state.tc}
                        closeNotification={() => this.setState({ tc: false })}
                        close
                    /> : ""}
                    <GridItem xs={12} sm={12} md={2}>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>{Settings.titleCase("Edit visitor record")}</h4>
                                <p className={classes.cardCategoryWhite}>{Settings.capitalizeFLetter("Update details pertaining to the visitor")}</p>
                            </CardHeader>
                            <CardBody>
                                {this.state.netWorkError ? <SnackbarContent
                                    icon={InfoIcon}
                                    message={this.state.erroertext}
                                    // close
                                    color="danger"
                                /> : ""}
                                {this.state.loader ? <LoaderComponent /> : ""}
                                <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                           labelText="Vehicle Number(max. 10 characters)"
                                            id="Vehicle Number"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {this.setState({ vehicle: event.target.value })
                                                if (Settings.Vehicle(event.target.value).status) {
                                                    this.setState({ flagError: false, erroertext: "" })
                                                } else {
                                                    this.setState({ flagError: true, erroertext: Settings.Vehicle(event.target.value).errmessgae, inputname: Settings.Vehicle(event.target.value).inputname })
                                                }
                                            },
                                                onInput: (e) => e.target.value = ("" + e.target.value).toUpperCase().toString().slice(0, 10),
                                                type: "text",
                                                disabled:true,

                                                value: this.state.vehicle,
                                                error: (this.state.flagError && this.state.inputname === "Vehicle") || (this.state.requirederror && this.state.vehicle === "") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.vehicle ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "Vehicle" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* Visitor Name"
                                            id="Name"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {this.setState({ visitorname: event.target.value })
                                                if (Settings.PersonName(event.target.value).status) {
                                                    this.setState({ flagError: false, erroertext: "" })
                                                } else {
                                                    this.setState({ flagError: true, erroertext: Settings.PersonName(event.target.value).errmessgae, inputname: Settings.PersonName(event.target.value).inputname })
                                                }
                                            },
                                                value: this.state.visitorname,
                                                type: "text",
                                                disabled:true,

                                                error: (this.state.flagError && this.state.inputname === "PersonName") || (this.state.requirederror && this.state.visitorname === "") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.visitorname ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "PersonName" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                   
                                </GridContainer>
                                <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="* Mobile Number(10 digits)"
                                            id="Mobile"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {this.setState({ mobile: event.target.value })
                                                if (Settings.mobilenumber(event.target.value).status || !event.target.value) {
                                                    this.setState({ flagError: false, erroertext: "" })
                                                } else {
                                                    this.setState({ flagError: true, erroertext: Settings.mobilenumber(event.target.value).errmessgae, inputname: Settings.mobilenumber(event.target.value).inputname })
                                                }
                                            },
                                                onInput: (e) => e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10),
                                                type: "number",
                                                disabled:true,

                                                value: this.state.mobile,
                                                error: (this.state.flagError && this.state.inputname === "Mobile") || (this.state.requirederror && this.state.mobile === "") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.mobile ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                        {this.state.flagError && this.state.inputname === "Mobile" ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>
                                   
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                             labelText="* To meet(max. 25 characters)"
                                            id="tomeet"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) =>{ this.setState({ tomeet: event.target.value })
                                                if (Settings.Comments(event.target.value).status) {
                                                    this.setState({ flagError: false, erroertext: "" })
                                                } else {
                                                    this.setState({ flagError: true, erroertext: Settings.Comments(event.target.value).errmessgae, inputname: Settings.Comments(event.target.value).inputname })
                                                }
                                            },
                                                type: "text",
                                                value: this.state.tomeet,
                                                error: (this.state.flagError && this.state.inputname === "Comments") || (this.state.requirederror && this.state.tomeet === "") ? true : false
                                            }}
                                        />
                                        {this.state.requirederror && !this.state.tomeet ? <SnackbarContent
                                            icon={InfoIcon}
                                            message={this.state.erroertext}
                                            // close
                                            color="danger"
                                        /> : ""}
                                    </GridItem>

                                </GridContainer>

                            </CardBody>
                            <CardFooter>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={5}>
                                        {this.state.visitorname !== "" && this.state.mobile !== "" &&  this.state.vehicle !== "" && this.state.tomeet !== "" ?
                                            <Button color="primary" onClick={this.add}> <DoneOutlineIcon />save<span style={{ display: "inline-block", width: "21px" }}></span></Button> :
                                            <Button onClick={() => {
                                                if (Settings.requireerrors([this.state.vehicle, this.state.mobile]).status === true) {
                                                    this.setState({ requirederror: true, erroertext: Settings.requireerrors([ this.state.visitorname, this.state.companyname]).errmessgae })
                                                    setTimeout(() => { this.setState({ requirederror: false }) }, 3000);
                                                }
                                            }}> <DoneOutlineIcon />save<span style={{ display: "inline-block", width: "21px" }}></span></Button>
                                        }
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={1}>
                                        <Button color="white" onClick={() => {
                                            this.props.history.push({
                                                pathname: '/visitor/view', state: {
                                                    propdata: this.props.location.state.propdata
                                                }
                                            })
                                        }}><CancelPresentationIcon />Cancel</Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                    </GridItem>
                                </GridContainer>
                            </CardFooter>
                        </Card>
                    </GridItem>

                </GridContainer>

            </div>
        );
    }
}
export default withRouter((withStyles(styles)(Edit)));


